import React, { useState } from 'react';
import { useHistory, Link } from "react-router-dom";
import Modal from 'react-modal';
import { globalStateValue } from './../../store/globalState';
import { useCustomers } from './../../hooks/useCustomers';
import cross from './../../theme/img/times-1.svg'


// Components
import {Helmet} from "react-helmet";
import lock from './../../theme/img/lock.svg';
import cards from './../../theme/img/cards.svg';
import dd from './../../theme/img/directdebit.jpeg';
import gcLogo from './../../theme/img/gc.png'

const Checkout = () => {
    const [summaryOpen, setSummaryOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [addressLineOne, setAddressLineOne] = useState('');
    const [addressLineTwo, setAddressLineTwo] = useState('');
    const [town, setTown] = useState('');
    const [telephone, setTelephone] = useState('');
    const [dob, setDob] = useState('');
    const [email, setEmail] = useState('');
    const [accountName, setAccountName] = useState('');
    const [sortCode, setSortCode] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [terms, setTerms] = useState(false);
    const [singleDirect, setSingleDirect] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [errors, setErrors] = useState({})
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalIsOpenD, setIsOpenD] = useState(false);
    const [state, dispatch] = globalStateValue();
    const history = useHistory();
    const { doSignUp } = useCustomers();

    const customStyles = {
        content: {
          top: '40%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
      };

        
    if(!state.customerData || !state.plan) {
        history.push('/')
    }

    const handleOpen = () => {
        setSummaryOpen(!summaryOpen);
    }

    const handleTitle = (e) => {
        emptyCheck(e);
        setTitle(e.target.value)
    }

    const handleFirstName = (e) => {
        emptyCheck(e);
        setFirstName(e.target.value)
    }
    const handleLastName = (e) => {
        emptyCheck(e);
        setLastName(e.target.value)
    }
    const handleAddressLineOne = (e) => {
        emptyCheck(e);
        setAddressLineOne(e.target.value)
    }
    const handleAddressLineTwo = (e) => {
        setAddressLineTwo(e.target.value)
    }
    const handleTown = (e) => {
        emptyCheck(e);
        setTown(e.target.value)
    }
    const handleTelephone = (e) => {
        emptyCheck(e);
        setTelephone(e.target.value)
    }
    const handleDob = (e) => {
        // emptyCheck(e);
        setDob(e.target.value)
    }
    const handleEmail = (e) => {
        emptyCheck(e);
        setEmail(e.target.value)
    }
    const handleAccountName = (e) => {
        emptyCheck(e);
        setAccountName(e.target.value)
    }
    const handleSortCode = (e) => {
        emptyCheck(e);
        setSortCode(e.target.value)
    }
    const handleAccountNumber = (e) => {
        emptyCheck(e);
        setAccountNumber(e.target.value)
    }
    const handleTerms = (e) => {
        setTerms(e)
    }

    const handleSingleDirect = (e) => {
        setSingleDirect(e);
    }

    const emptyCheck = (e) => {
        const { name, value } = e.target;
        const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
        const phoneRegex = RegExp(/^\s*(([+]\s?\d[-\s]?\d|0)?\s?\d([-\s]?\d){9}|[(]\s?\d([-\s]?\d)+\s*[)]([-\s]?\d)+)\s*$/i);
        const sortCode = RegExp(/^(?!(?:0{6}|00-00-00))(?:\d{6}|\d\d-\d\d-\d\d)$/);
        const validAccount = RegExp(/^\d{8}$/);


        switch (name) {
            case 'firstName': 
              errors.firstName = 
                value.length < 2
                  ? 'First Name must be 2 characters long!'
                  : '';
              break;
            case 'lastName': 
              errors.lastName = 
                value.length < 2
                  ? 'Last Name must be 2 characters long!'
                  : '';
              break;
            case 'ad1': 
              errors.ad1 = 
                value.length <= 2
                  ? 'Address must be 2 characters long!'
                  : '';
              break;
            case 'town': 
              errors.town = 
                value.length <= 3
                  ? 'Address must be 2 characters long!'
                  : '';
              break;
            case 'email': 
              errors.email = 
                validEmailRegex.test(value)
                  ? ''
                  : 'Email is not valid!';
              break;
            case 'dob': 
              errors.dob = 
                value.length <= 3
                  ? ''
                  : 'Date of birth is not valid';
              break;
            case 'telephone': 
              errors.telephone = 
                phoneRegex.test(value)
                  ? ''
                  : 'Phone number is not valid';
              break;
            case 'accountName': 
              errors.accountName = 
                value.length <= 3
                  ? 'Account name is not valid'
                  : '';
              break;
            case 'sortCode': 
              errors.sortCode = 
                sortCode.test(value)
                  ? ''
                  : 'Sort-code not valid';
              break;
            case 'accountNumber': 
              errors.accountNumber = 
                validAccount.test(value)
                  ? ''
                  : 'Bank account is not valid!';
              break;
            default:
              break;
          }
    }

    const openModal = () => {
        setIsOpen(true);
    }

    const closeModal = () => {
        setIsOpen(false);
    }

    const openModalD = () => {
        setIsOpenD(true);
    }

    const closeModalD = () => {
        setIsOpenD(false);
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        // Send to backend
        dispatch({
            type: 'UPDATE_CUSTOMER',
            customerData: {
                email
            }
        });

        doSignUp(title, firstName, lastName, addressLineOne, addressLineTwo, town, state.customerData.postcode, telephone, dob, email, accountName, sortCode, accountNumber, terms, state.plan.payload.id)
        
        
        .then((reponse) => {
            if(!reponse) {
                setTimeout(() => { 
                    history.push('/checkout-thanks')
                }, 1000);
            } else {
                setTimeout(() => { 
                    history.push('/error', {user: email, message: reponse})
                }, 1000);
            }
            // history.push('/checkout-thanks')
            // setError(reponse)
        })
        .catch(error => {
            setError(error)
        });


        // Send to goCardless
    }


    const isInvalid =
      title === '' ||
      errors?.firstName !== '' ||
      errors?.lastName !== '' ||
      errors?.ad1 !== '' ||
      errors?.town !== '' ||
      errors?.telephone !== '' ||
      dob === '' ||
      errors?.email !== '' ||
      errors?.accountName !== '' ||
      errors?.sortCode !== '' ||
      errors?.accountNumber !== '' ||
      terms === false ||
      singleDirect === false

    const pageTitle = 'Checkout';
    return (
        <div className={'c-checkout'}>
            {isLoading &&
                <div className="loading-bar">
                    <div className="in"></div>
                </div>    
            }
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            <div class="checkout-content">
                <h3 class="summary" onClick={handleOpen}>Summary</h3>

                <div class={`summary-area ${summaryOpen ? 'is-open': ''}`}>
                    <hr />
                    <h3 class="flex space-between">
                        <span>{state?.plan?.payload?.name}</span>
                        <span>{state?.plan?.payload?.price} p/m</span>
                    </h3>
                    <ul>
                        <li>24 month contract</li>
                        <li>{state?.plan?.payload?.downloadSpeed} Mbps Download Speed</li>
                        <li>{state?.plan?.payload?.uploadSpeed} Mbps Upload Speed</li>
                        <li>Truely unlimited downloads</li>
                        <li>FREE activation</li>
                        <li>FREE WiFi 6 Mesh Router</li>
                    </ul>
                    <hr />
                    <ul>
                        <li class="flex space-between">
                            <span>Installation</span>
                            <span>Free</span>
                        </li>
                        <li class="flex space-between">
                            <span>Total</span>
                            <span>{state?.plan?.payload?.price} p/m</span>
                        </li>
                    </ul>
                    <hr />
                    <h3 class="flex space-between">
                        <span>Direct Debit Details</span>
                    </h3>
                        
                    <ul>
                    {accountName &&<li class="flex space-between">
                        <span>Account Name</span>
                        <span>{accountName}</span>
                    </li>}
                    {sortCode && <li class="flex space-between">
                        <span>Sort Code</span>
                        <span>{sortCode}</span>
                    </li>}
                    {accountNumber && <li class="flex space-between">
                        <span>Account Number</span>
                        <span>{accountNumber}</span>
                    </li>}
                    <p className="small-paragraph">You may cancel this Direct Debit at any time by contacting Exascale or your bank.</p>
                    </ul>

                </div>

            </div>
            <div class="checkout-area">
                <div class="container container-lg">
                    <div class="row">
                        <div class="col-12">
                            <form onSubmit={handleSubmit}>
                                {error && error.message && <div className={'alert alert-danger'}><strong>Error:</strong> {error.message}</div>}
                                <div class="checkout">
                                    <h3>Personal details</h3>
                                    <p>Firstly, we need to collect a few personal details about you. </p>

                                    <div class="form-group">
                                        <select name="title" class={`form-control ${errors.title ? 'form-error': ''}`} onChange={e => handleTitle(e)}>
                                            <option>Select your title</option>
                                            <option>Mr</option>
                                            <option>Mrs</option>
                                            <option>Miss</option>
                                            <option>Ms</option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <input type="text" name="firstName" class={`form-control ${errors.firstName ? 'form-error': ''}`} placeholder="First name" onChange={e => handleFirstName(e)}/>
                                    </div>
                                    <div class="form-group">
                                        <input type="text" name="lastName" class={`form-control ${errors.lastName ? 'form-error': ''}`} placeholder="Last name"onChange={e => handleLastName(e)}  />
                                    </div>
                                    <div class="form-group">
                                        <input type="text" name="ad1" class={`form-control ${errors.ad1 ? 'form-error': ''}`} placeholder="Address Line 1"onChange={e => handleAddressLineOne(e)} />
                                    </div>
                                    <div class="form-group">
                                        <input type="text" name="ad2" class={`form-control ${errors.ad2 ? 'form-error': ''}`} placeholder="Address Line 2"onChange={e => handleAddressLineTwo(e)} />
                                    </div>
                                    <div class="form-group">
                                        <input type="text" name="town" class={`form-control ${errors.town ? 'form-error': ''}`} placeholder="Town" onChange={e => handleTown(e)}  />
                                    </div>
                                    <div class="form-group">
                                        <input type="text" disabled class="form-control" value={state?.customerData?.postcode} />
                                    </div>
                                    <div class="form-group">
                                        <small>Date of Birth</small>
                                        <input  class={`js-datepicker--dob form-control ${errors.dob ? 'form-error': ''}`} name="dob" type='date' onChange={e => handleDob(e)} />
                                    </div>
                                    <div class="form-group">
                                        <input type="tel"  name="telephone" class={`form-control ${errors.telephone ? 'form-error': ''}`}
                                             placeholder="Phone number" onChange={e => handleTelephone(e)}  />
                                    </div>
                                    <div class="form-group">
                                        <input type="email"  class={`form-control ${errors.email ? 'form-error': ''}`} name="email" placeholder="Email address" onChange={e => handleEmail(e)} />
                                    </div>

                                    <h3>Payment details </h3>
                                    <p>We’ll ask your bank to set up a monthly Direct Debit. </p>
                                    <div class="form-group">
                                        <input type="text"  name="accountName" class={`form-control ${errors.accountName ? 'form-error': ''}`} placeholder="Name on account" onChange={e => handleAccountName(e)}  />
                                    </div>

                                    <div class="form-group sort-code">
                                        <input type="tel" class={`form-control ${errors.sortCode ? 'form-error': ''}`} name="sortCode" placeholder="Sort code" onChange={e => handleSortCode(e)}/>
                                    </div>
                                    <div class="form-group">
                                        <input type="tel"  name="accountNumber" class={`form-control ${errors.accountNumber ? 'form-error': ''}`} placeholder="Account number" onChange={e => handleAccountNumber(e)}/>
                                    </div>
                                    <div class="form-group form-check">
                                        <input type="checkbox" class={`form-check-input form-control ${errors.terms ? 'form-error': ''}`} name="terms" id="checkbox" onChange={e => handleTerms(e.target.checked)}/>
                                        <label class="form-check-label " for="checkbox" >
                                            Please tick the box to confirm that you have read and agree to the terms and conditions, that you are over the age of 18 and that you would like your services and contract to start as soon as possible. <a onClick={openModal}>For more information regarding our terms and conditions</a>
                                        </label>
                                    </div>
                                    <div class="form-group form-check">
                                        <input type="checkbox" class={`form-check-input form-control ${errors.singleDirect ? 'form-error': ''}`} name="singleDirect" id="checkbox-terms" onChange={e => handleSingleDirect(e.target.checked)}/>
                                        <label class="form-check-label " for="checkbox-terms" >
                                            Please tick the box to confirm your details are correct in the summary and you are the only person required authorise a direct debit on the account. We do not support joint accounts. <a onClick={openModalD}>Read our direct debit guarantee</a>
                                        </label>
                                    </div>
                                    <Modal
                                        isOpen={modalIsOpenD}
                                        onRequestClose={closeModalD}
                                        style={customStyles}
                                        contentLabel="Direct Debit Guarantee"
                                        >
                                        <button className="btn-close" onClick={closeModalD}><img src={cross} /></button>
                                        <div className="modal-content">
                                        <h1>Direct Debit Guarantee</h1>

<p>The Guarantee is offered by all banks and building societies that accept instructions to pay Direct Debits</p>
<p>If there are any changes to the amount, date or frequency of your Direct Debit GC Re EXASCALE will notify you (normally 10 working days) in advance of your account being debited or as otherwise agreed. If you request GC Re EXASCALE to collect a payment, confirmation of the amount and date will be given to you at the time of the request</p>
<p>If an error is made in the payment of your Direct Debit, by GC Re EXASCALE or your bank or building society, you are entitled to a full and immediate refund of the amount paid from your bank or building society</p>
<p>If you receive a refund you are not entitled to, you must pay it back when GC Re EXASCALE asks you to</p>
<p>You can cancel a Direct Debit at any time by simply contacting your bank or building society. Written confirmation may be required. Please also notify Exascale.</p>
                                        </div>
                                    </Modal>
                                    <Modal
                                        isOpen={modalIsOpen}
                                        // onAfterOpen={afterOpenModal}
                                        onRequestClose={closeModal}
                                        style={customStyles}
                                        contentLabel="Terms and Conditions"
                                        >
                                        <button className="btn-close" onClick={closeModal}><img src={cross} /></button>
                                        <div className="modal-content">
                                        <h1>Broadband Terms and Conditions</h1>
<p>This document supersedes all previous versions.</p>
<p>Updated: 14/01/2017</p>
 
<p>This Agreement is made between Exascale Limited, company number 07120266, registered at Unit 26, Trench Lock 3, Telford, TF15 ST ("We") or (“Exascale”) and the User ("You") or (“Your”).  The following constitute the Terms and Conditions under which Exascale Limited trades and supplies its services and related products. These conditions, in conjunction with the details as shown on the Exascale Limited ordering form represent the totality of the agreement and form the Contract between Exascale Limited and the User.</p>

<p>Any agreed variation or alteration to part of these Terms and Conditions as annexed to this Contract will not invalidate the remainder or the whole. Any alteration or addition to the supplies instructed on the order will require being the subject of a new order. Exascale Limited is entitled to suspend services if the User does not adhere to any or several conditions of this Contract.</p>

<h2>1.	DEFINITIONS </h2>

<p>In this Contract unless the context otherwise requires:</p>

<p><strong>Bandwidth</strong>	means the allocated transmission capacity, measured in bits per second, of the network connection as specified in the Contract; </p>
<p><strong>Broadband</strong>	means the transmission bandwidth via a coax, optical fiber, twisted pair or wireless link;</p>

<p><strong>Contract</strong>	means the Contract between Exascale and You incorporating these conditions, the Exascale Order Form (where completed) and Exascale’s published charges for the provision of its services;</p>
<p><strong>Charges</strong>	means the charges as agreed on the ordering form and Exascale’s latest published prices for products and services requested or incurred
during the Contract period;</p>
<p><strong>Direct Debit</strong>	means a financial transaction in which Exascale withdraws funds from your bank account as part of the BACS Direct Debit scheme.</p>
<p><strong>Equipment</strong>	means any equipment, apparatus or software provided by us as an essential part of providing Services under the terms of this Contract, Including without limitation any reception/transmission equipment forming part of our fixed line or wireless system, as well as any equipment provided to you for making Voice calls.</p>
<p><strong>Fair Usage Policy</strong>	means the policy set by Exascale relating to the fair usage of Exascale’s network and Service(s). The policy may be downloaded from http://www.exascale.co.uk and forms part of this Contract.</p>
<p><strong>Internet</strong>	means the global data network comprising inter-connected networks using TCP/IP (TransmissionControl Protocol/Internet Protocol) Internet Standards means the protocols and standards defined in the following Internet documents: RFC 1009, 1122, 1123 and 1250 and any future such protocols and standards as appropriate;</p>
<p><strong>Minimum contract term</strong>	means the minimum period of service as specified in clause 6.1;</p>
<p><strong>Router</strong>	means a device that forwards data packets along networks. A router is connected to at least two networks, commonly two LANs or WANs or a LAN and its ISP’s network. Routers are located at gateways, the places where two or more networks connect;</p>
<p><strong>Service Provider</strong>	means Exascale or any other company offering communications services.</p>
<p><p><strong>Services</strong>	means domain name registration,
Broadband or other
connectivity, web site hosting, bandwidth provision, email, telephony, VoIP and any other
service or facility provided by us to you;</p>
<strong>Site</strong> or <strong>Premises</strong>
	means the location at which Service is or is to be
provided under this Contract;</p>
<p><strong>User</strong>	means you the customer or any person who makes use of the services though you or on your
behalf;</p>
<p><strong>VAT</strong>	means Value Added Tax</p>

<h2>2.	FAIR USAGE POLICY</h2>

<p>2.1.	All Exascale packages unless otherwise stated on the order form, are based on contention, that is, the number of other people with whom you share your service connection. In order to allow everyone online and for us to guarantee quality services for all, we have in place a Fair Usage Policy with our packages. Exascale continuously monitors its network and may take measures to control congestion if there is evidence that this is occurring. If usage is such that it is significantly affecting service to other Exascale users we will instigate congestion control measures. This is necessary to allow all users to access the service. Our Fair Usage Policy is located at http://www.exascale.co.uk and forms part of this Contract.</p>

<h2>3.	CONTENT MISUSE</h2>

<p>3.1.	You will use all reasonable endeavors to ensure that the Exascale Service is used or includes content that conforms to the laws of the this country and will not knowingly permit any illegal use or such use that will bring Exascale into disrepute;</p>

<p>3.2.	You must not, nor must any other User, use the Service: to send or receive any material which is offensive, abusive, indecent, obscene or menacing; or in breach of confidence, copyright, privacy or any other rights; to cause annoyance, inconvenience or needless anxiety; or other than in conformance with the acceptable use policies of any connected networks and the Internet standards.</p>

<p>3.3.	Hosting a server is not permitted unless on a Business package.</p>

<p>3.4.	Exascale may discontinue Service if after due warning if the User continues to permit such illegal or disreputable activity. If Exascale suspends service for contravention of the above conditions of this clause, Exascale can refuse to restore Service until it receives an acceptable assurance from the User that there will be no further contravention;</p>

<h2>4.	SERVICES</h2>

<p>4.1.	Connection to the Exascale Service is via Broadband or any other connection. This Contract, unless specifically stated on the order form, may or may not include the provision of Telecommunications services necessary for connection to the Exascale Service;</p>

<p>4.2.	Exascale will use all reasonable endeavors to adhere to any dates proposed by either Exascale or you for the provision of Service, however any such date is to be treated as an estimate only and Exascale accepts no liability for failure to meet such dates;</p>

<p>4.3.	Exascale will use all reasonable endeavors to provide a reliable Service, however it is not practicable to provide such a Service free of faults and Exascale does not undertake to do so. In the event of a fault in Service, you must report the fault by telephone, fax or electronic mail to Exascale at the appropriate numbers or addresses or other such numbers or addresses that Exascale may from time to time provide. Upon receipt of the fault report, Exascale will take all proper steps without undue delay to correct the fault. Exascale shall not, in any event, be liable for interruptions of Service or down‐time.</p>

<p>4.4.	Exascale may:</p>

<p>4.4.1.	Temporarily suspended for the purpose of repair, maintenance or improvement, part or all of Exascale service, generally without notice.</p>

<h2>5.	CHARGES AND PAYMENT</h2>

<p>5.1.	All payments must be made in GBP via Direct Debit.</p>

<p>5.2.	If we set up a Direct Debit on your bank account and you or your bank or building society cancel it, we reserve the right to charge £10.00 +VAT to cover administration and banking charges.</p>

<p>5.3.	If for any reason the Direct Debit payment is returned by your Bank or building society, we reserve the right to charge £10.00 +VAT to cover administration and banking charges.</p>

<p>5.4.	A valid Direct Debit instruction must  be  maintained  on  your bank or building society account otherwise Exascale  reserves the right to immediately suspend any account and associated Service(s) where a valid form of payment is not present.</p>

<p>5.5.	You  acknowledge  that  our  Services may be provided  using facilities provided to us by third parties; Exascale shall  have  the  right,  subject  to 14  days  prior  written  notice  to  you,  to  increase  our  Charges  at  any time during  the  minimum  Contract  term  or  the  continuation  of  the Contract, whether to reflect increase costs to us  from  such  third parties  or  otherwise.  However,  if  such  increase  exceeds  10%  of  the charge  in question  prior  to  such  notice  you  shall  be  entitled  to terminate  this  contract  by  written  notice  to  us given  by  you  within  7 days  after  service  of  our  notice  of  increase  to  you.  If  you  do  so terminate, you will remain liable for all charges (at the previous rate) up to the date this contract ends;</p>

<h2>6.	BROADBAND EQUIPMENT</h2>

<p>6.1.	We will need to access your premises from time to time for example to repair, maintain, replace or upgrade any Equipment installed on your premises, or to recover our Equipment following any termination of this Contract.</p>

<p>6.2.	If we require access, we will contact you to arrange an appointment for us to attend your premises for this purpose. You agree to cooperate with us in arranging a suitable time for us to attend your premises.</p>

<p>6.3.	With the exception of the Router, all other Equipment will remain our property at all times unless we notify you otherwise in writing. You must not give anyone else any rights over it. We may add to or substitute the Equipment if we have a valid reason.</p>

<p>6.4.	The installation costs include 6 metres of cable and associated clips, if the installation requires more than 6 metres a charge of £3.00 +VAT per metre will apply.</p>

<p>6.5.	You must not tamper with, add to, modify or interfere with the Equipment in any way, and you must not allow any other person to do so.</p>

<p>6.6.	You are responsible for the loss or the damage of Equipment unless this is:</p>

<p>6.6.1.	caused by us, our employees or our contractors; or</p>
<p>6.6.2.	due to a fault in the Equipment, or to general wear and tear.</p>

<p>6.7.	If we need to replace equipment that is damaged by reasons beyond our control such as faults caused by electrical surges or storms or any other reason, there may be a service fee for carrying out such work. Service fees are currently £60 +VAT. In addition, a requested service visit for a fault that is not a result of our service or equipment but is a fault generated by you or equipment that you own will result in as service fee being charged.</p>

<p>6.8.	Failure to return any item of the Equipment may result in additional Charges to you for each individual item of Equipment. Such Charges will not exceed £500 for each individual item of Equipment.</p>

<p>6.9.	Upon termination of this Contract, if you require our Equipment removing from your Premises, a de-installation fee of £40.00 + VAT will apply. Alternatively, if our Equipment is made available for collection, no fee will apply.</p>

<p>6.10.	If you connect our Equipment to a Local Area Network (LAN) then you are responsible for:</p>

<p>6.10.1.	supplying, configuring and maintaining a suitable piece of equipment (for example, a router, wireless access point or hub) capable of interfacing with our Equipment.</p>
<p>6.10.2.	notifying us of the technical contact for your LAN.</p>

<p>6.11.	By accepting these Conditions you understand that we will not provide any technical assistance for your home or office LAN.</p>

<h2>7.	IP ADDRESSES</h2>

<p>7.1.	All IP addresses assigned to your remain the property of Exascale, and you must not sell, attempt to sell, or agree to transfer them to anyone else.</p>

<p>7.2.	All Broadband Services are assigned a dynamic IP address.</p>

<h2>8.	ONLINE SECURITY</h2>

<p>8.1.	You are solely responsible for the security of your computers. We are not responsible for any breach of security via the Internet.  We recommend you ensure your machine or LAN is configured correctly and has firewall software or a separate hardware firewall to protect you from the Internet. This is particularly important if you use our Service to maintain a permanent connection to the Internet.</p>


<h2>9.	COMMENCEMENT AND MINIMUM PERIOD OF SERVICE</h2>

<p>9.1.	The Service shall be for a minimum period of 24 (“Twenty Four”) months, unless specified on any ordering form (online or paper). The minimum period shall commence upon connecting to our service. Connection Shall be deemed to be effective when you first make a connection, or requested by you, to the Exascale network.</p>


<h2>10.	DEFAULT</h2>

<p>10.1.	If you: do not pay the charges in accordance with the provisions of clause 9 of this Contract or break this Contract in any other way; or are subject to bankruptcy or insolvency proceedings; Exascale can (without prejudicing, losing or reducing any other right or remedy) suspend service, including partially, temporarily without notice, albeit the User remains liable to pay rental during the suspension, or terminate this Contract by immediate notice, without prejudice to Exascale's pre‐existing rights;</p>

<p>10.2.	Upon point 10.1 being implemented Exascale may outsource a third party debt recovery specialist or start legal proceedings to recover any costs outstanding on your account. Administration and court costs will be incurred on your account along with any penalties as set out in the provisions of clause 5 for not completing the contracted term.</p>

<p>10.3.	Bankruptcy or insolvency proceeding means bankruptcy proceedings or in Scotland sequestration proceedings, becoming insolvent, making any composition or arrangement with creditors or an assignment for their benefit, any execution, distress, diligence or seizure: or if the User is a company, being the subject of proceedings for the appointment of an administrator, going into liquidation whether voluntary or compulsory (except for the purpose of amalgamation or reconstruction) or having a receiver or administrative receiver of any assets appointed;</p>

<p>10.4.	You continue to be liable to pay all charges which are due for Service during any period in which you do not comply with this Contract;</p>

<p>10.5.	If Exascale waives a breach of Contract by you, that waiver is limited to the particular breach. Any delay by Exascale in acting upon a breach is not to be regarded in itself as a waiver.</p>

<h2>11.	TERMINATION, NOTICES AND CHANGES OF SERVICE</h2>

<p>11.1.	This Contract may be terminated by either party on giving at least 30 (“thirty”) day’s notice to the other expiring on the last day of the minimum Contract term or at any time thereafter. If Exascale gives notice you shall pay all charges up to the expiry of the notice. If you give notice, you shall pay all charges until 30 (“thirty”) days after the date. Exascale receives the notice or until expiry of the notice, whichever is the latter. Your notice does not avoid any other liability for Service already provided.</p>

<p>11.2.	Notice will not be effective until the end of the month after the month in which the notice was given (i.e., notice received April 20th is effective at the end of May 31st));</p>
			
<p>11.3.	Exascale reserves the right to terminate your account without notice, but normally suspending accounts will be the first cause of action.</p>

                                        </div>
                                        </Modal>
                                    <button disabled={isInvalid} class="btn-default">Order Now</button>
                                </div>
                            </form>
                            <div class="checkout-secure">
                                <h4>
                                    <img src={lock} alt="lock"/>
                                    <span>Secure purchasing</span>
                                </h4>
                                <p>If you change your mind about choosing Exascale services within 14 days of signing up then you should be able to cancel your services and will only have to pay for the services you have used and return any equipment. If you cancel after this time, you may have to pay an early termination charge. </p>
                                <img src={cards} alt="card"/>
                                <a href="https://www.directdebit.co.uk/DirectDebitExplained/pages/directdebitguarantee.aspx" target="_blank"><img src={dd} alt="direct debit" className="icon-dd"/></a>
                                <span class="icon-gc-test">Payments by </span><a  href="https://gocardless.com/privacy" target="_blank"><img className="icon-gc" src={gcLogo} alt="GoCardLess"/></a>
                                <p style={{fontSize: '12px'}}>Read the <a href="https://gocardless.com/privacy" target="_blank">GoCardless privacy notice</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Checkout;
