import React, {useState} from 'react';
import {validTelephone} from './../../utils/helpers';
import { globalStateValue } from './../../store/globalState';
import { useCustomers } from './../../hooks/useCustomers';
import { useHistory } from "react-router-dom";

// Components
import {Helmet} from "react-helmet";

const RegisterInterestTwo = () => {
    const pageTitle = 'Want super-fast broadbroad in your area';

    const [number, setNumber] = useState('');
    const [provider, setProvider] = useState('');
    const [errors, setErrors] = useState({});
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const history = useHistory();
    const [state, dispatch] = globalStateValue();
    const { doLead } = useCustomers();

    if(!state.customerData) {
        history.push('/')
    }

    const isInvalid =
    errors?.telephone !== '' 


    const emptyCheck = (e) => {
        const { name, value } = e.target;
        const phoneRegex = RegExp(/^\s*(([+]\s?\d[-\s]?\d|0)?\s?\d([-\s]?\d){9}|[(]\s?\d([-\s]?\d)+\s*[)]([-\s]?\d)+)\s*$/i);


        switch (name) {
            case 'telephone': 
              errors.telephone = 
                phoneRegex.test(value)
                  ? ''
                  : 'Phone number is not valid';
              break;
            default:
              break;
          }
    }


    const handlePhone = (inputValue) => {
        emptyCheck(inputValue)
        setNumber(inputValue.target.value)
    }
    const handleProvider = (inputValue) => {
        setProvider(inputValue.target.value)
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        if(!isInvalid){
            history.push("/register-interest-complete");

            const payload = {
                ...state.customerData,
                number,
                provider: provider !== '' ? provider : ''
            }

            // Send to backend
            doLead(payload)
            .then((reponse) => {
                setTimeout(() => { 
                    history.push('/register-interest-complete')
                }, 1000);
                // setError(reponse)
            })
            .catch(error => {
                setError(error)
            });
        } else {
            console.log('invalid')
        }
    }


    return (
        <div className={'c-register-interest'}>
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            <div class="not-available-area">
            {isLoading &&
                <div className="loading-bar">
                    <div className="in"></div>
                </div>    
            }
                <div class="container container-lg">
                    <div class="row">
                        <div class="col-12">
                            <div class="not-available">
                                <h2>Want Super-Fast broadband in your area?</h2>
                                <p>We’re not quite there yet, apply here and we’ll prioritise <strong>{`${state.customerData.address1}, ${state.customerData.postcode}.`}
                                        </strong>
                                </p>
                                <form onSubmit={handleSubmit}  class="form">
                                    <div class="form-group">
                                        <input type="text" name="telephone" className={`form-control ${errors.telephone ? 'form-error': ''}`} placeholder="Phone number" onChange={(e) => handlePhone(e)}/>
                                    </div>
                                    <div class="form-group">
                                        <select class="form-control" onChange={(e) => handleProvider(e)}>
                                          <option>Current internet providor</option>
                                          <option>BT</option>
                                          <option>Plusnet</option>
                                          <option>Sky</option>
                                          <option>Virgin Media</option>
                                          <option>TalkTalk</option>
                                          <option>Zen</option>
                                          <option>Other</option>
                                        </select>
                                      </div>
                                    <button disabled={isInvalid} type="submit" class="btn-default">Prioritise me</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegisterInterestTwo;
