import React from 'react';

// Components
import {Helmet} from "react-helmet";

const RegisterInterestThree = () => {
    const pageTitle = 'Thanks for your application';
    
    return (
        <div className={'c-register-interest'}>
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            <div class="not-available-area">
                <div class="container container-lg">
                    <div class="row">
                        <div class="col-12">
                            <div class="not-available">
                                <h2>Thanks for your application</h2>
                                <p>
                                    We’ll be in touch as soon as Exascale Super-Fast broadband is available in your area. 
                                    <br/>
                                    <br/>
                                    To keep up to date with how we’re progressing, follow us on Facebook:
                                </p>
                                <a href="#" class="btn-default">Follow us on Facebook</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegisterInterestThree;
