import React, {useState, useEffect} from 'react';
import {validEmail} from './../../utils/helpers'
import { useHistory } from "react-router-dom";
import {Helmet} from "react-helmet";
import { globalStateValue } from './../../store/globalState';



const RegisterInterestOne = () => {
    const pageTitle = 'Want super-fast broadbroad in your area';
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [address3, setAddress3] = useState('');
    const [errors, setErrors] = useState({})
    const [terms, setTerms] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const history = useHistory();
    const [state, dispatch] = globalStateValue();


    const handleFName = (e) => {
        emptyCheck(e);
        setFirstName(e.target.value)
    }
    const handleLastName = (e) => {
        emptyCheck(e);
        setLastName(e.target.value)
    }
    const handleEmail = (e) => {
        emptyCheck(e);
        setEmail(e.target.value)
    }

    const handleTerms = (inputValue) => {
        setTerms(Boolean(inputValue))
    }

    const handleAdd1 = (e) => {
        emptyCheck(e);
        setAddress1(e.target.value)
    }

    const handleAdd2 = (e) => {
        setAddress2(e.target.value)
    }

    const handleAdd3 = (e) => {
        emptyCheck(e);
        setAddress3(e.target.value)
    }

    const emptyCheck = (e) => {
        const { name, value } = e.target;
        const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);


        switch (name) {
            case 'firstName': 
              errors.firstName = 
                value.length < 2
                  ? 'First Name must be 2 characters long!'
                  : '';
              break;
            case 'lastName': 
              errors.lastName = 
                value.length < 2
                  ? 'Last Name must be 2 characters long!'
                  : '';
              break;
            case 'ad1': 
              errors.ad1 = 
                value.length <= 2
                  ? 'Address must be 2 characters long!'
                  : '';
              break;
            case 'ad3': 
              errors.ad3 = 
                value.length <= 3
                  ? 'Address must be 2 characters long!'
                  : '';
              break;
            case 'email': 
              errors.email = 
                validEmailRegex.test(value)
                  ? ''
                  : 'Email is not valid!';
              break;
            default:
              break;
          }
    }

    const isInvalid =
    errors?.firstName !== '' ||
    errors?.lastName !== '' ||
    errors?.ad1 !== '' ||
    errors?.ad3 !== '' ||
    errors?.email !== '' ||
    terms === false


    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        if(!isInvalid){
            dispatch({
                type: 'UPDATE_CUSTOMER',
                customerData: {
                    firstName,
                    lastName,
                    email,
                    address1,
                    address2,
                    address3,
                    terms,
                    postcode: state.customerData.postcode
                }
            });

            setTimeout(() => { 
                history.push("/register-interest-part-2");
            }, 1000);
        } 
    }


    return (
        <div className={'c-register-interest'}>
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            <div class="not-available-area">
            {isLoading &&
                <div className="loading-bar">
                    <div className="in"></div>
                </div>    
            }
                <div class="container container-lg">
                    <div class="row">
                        <div class="col-12">
                            <div class="not-available">
                                <h2>Want Super-Fast broadband in your area?</h2>
                                <p>We’re not quite there yet, apply here and we’ll prioritise <strong>{state?.customer.postcode}.</strong>
                                </p>
                                <form  class="form" onSubmit={handleSubmit}>
                                    <div class="form-group">
                                        <input type="text" name="firstName" className={`form-control ${errors.firstName ? 'form-error': ''}`}  placeholder="First name"
                                            onChange={e => handleFName(e)}
                                        />
                                    </div>
                                    <div class="form-group">
                                        <input type="text" name="lastName" className={`form-control ${errors.lastName ? 'form-error': ''}`}  placeholder="Last name"
                                            onChange={e => handleLastName(e)}
                                        />
                                    </div>

                                    <div class="form-group">
                                        <input type="text" name="ad1" className={`form-control ${errors.ad1 ? 'form-error': ''}`}  placeholder="Address Line 1"
                                            onChange={e => handleAdd1(e)}
                                        />
                                    </div>

                                    <div class="form-group">
                                        <input type="text" name="ad2" className={`form-control ${errors.ad2 ? 'form-error': ''}`} class="form-control" placeholder="Address Line 2"
                                            onChange={e => handleAdd2(e)}
                                        />
                                    </div>

                                    <div class="form-group">
                                        <input type="text" name="ad3" className={`form-control ${errors.ad3 ? 'form-error': ''}`} class="form-control" placeholder="Town"
                                            onChange={e => handleAdd3(e)}
                                        />
                                    </div>

                                    <div class="form-group">
                                        <input type="email" name="email" className={`form-control ${errors.email ? 'form-error': ''}`} placeholder="Email address"
                                            onChange={e => handleEmail(e)}
                                        />
                                    </div>
                                    <div class="form-group form-check">
                                        <input type="checkbox" class="form-check-input form-control" id="checkbox" value={!terms} onChange={e => handleTerms(e.target.value)} />
                                        <label class="form-check-label " for="checkbox">
                                        Please tick the box to confirm that you have read and agree to the terms and conditions, that you are over the age of 18 and that you would like to receive updates on Gigabit broadband. 
                                        </label>
                                    </div>
                                    <button  disabled={isInvalid} type="submit" class="btn-default">Next</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegisterInterestOne;
