import React from 'react';

// Components
import {Helmet} from "react-helmet";

const Privacy = () => {
    const pageTitle = 'Privacy';
    return (
        <div className={'c-privacy c-content-page'}>
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>


            <div class="container-lg">
                    <div class="row">
                        <div class="col-12">
                            <h1>Privacy Policy</h1>

                            <h2>PERSONAL DATA</h2>
<p>The Personal Data and Browser Data (hereinafter collectively referred to as “Data”), automatically collected from this website (hereinafter the “Site”), by means of automated systems or freely entered by the User or the Interested Party, are collected for the purposes, and processed, as follows below.</p>

<h2>OWNER OF THE DATA PROCESSING SYSTEM</h2>
<p>Exascale ta Exascale Ltd – Hilton Hall, Hilton Lane, Essington, Wolverhampton, West Midlands, WV112BQ, United Kingdom</p>

<h2>KINDS OF DATA</h2>
<p>The Data that may be collected by this Site are name, surname, address, phone number, email, fax, social security number, IP address and other Data relating to the User’s identity or technical access to the Site by the User. When Sensitive Personal Data are transferred (personal data revealing racial or ethnic, religious, philosophical or other beliefs, political opinions, membership of political parties, trade unions, associations or organizations of a religious, philosophical or political nature, and personal data disclosing information about health and sex life), it may be necessary to obtain consent in order to process them.</p>

<p>The Browser Data referring to the technical use of the Site must be collected, and transfer is not optional. The User may freely provide the remaining Data, including personal information, unless it is expressly indicated otherwise.</p>

<p>Failure to process certain Data (e.g. contact details, or addresses, or data concerning behavior or user preferences, etc.) may make it impossible for the Site to provide its services.</p>

<p>If the User utilizes the Site to publish or otherwise share Personal Data with third parties, he or she assumes full responsibility: the User states possession of the right to communicate or disclose personal data to third parties, and that they have received prior information thereto, thus relieving the Site of all responsibility for their improper use following disclosure or dissemination.</p>

<h2>METHOD OF PROCESSING</h2>
<p>The Data Controller processes the Data of the Interested Parties and Users in a lawful and proper manner and shall take appropriate security measures to prevent unauthorized access, disclosure, modification or unauthorized destruction of the Data. Processing is carried out using computers and / or telematic means, with organizational methods and logics strictly related to the stated purposes. In addition to the owner, in some cases, access to the Data may be available to personnel involved in the organization of the Site (administrative, commercial, marketing, legal, system administrators) or external parties (such as third party technical service providers, mail carriers , hosting providers, IT companies, communications agencies) appointed, if necessary, as Managers by the Owner of the Processing procedure. The updated list of Managers can be requested from the Owner by contacting the above address at any time.</p>

<h2>PLACE OF PROCESSING</h2>
<p>The Data are processed at the headquarters of the Data Controller. Any processing of Data outside one of the EU Member States may require the consent of the User or the
Interested Party.</p>

<h2>TIME OF PROCESSING</h2>
<p>The Data are kept for the time necessary to perform the service requested by the User, and the User can always ask for removal from the servers of the Site for legitimate reasons.</p>

<h2>THE USE OF THE COLLECTED DATA</h2>
<p>The provision of the service or delivery of goods for sale purchased on the Site, and more generally, for administrative and accounting or technical purposes;</p>

<p>The Provision Of The Service <br />
a response to specific requests for information by the User;<br />
a User update on new products or services provided by the Site or concerning particular business offers;<br />
the User to be informed of any Site maintenance or inconvenience;<br />
any commercial profiling of the User;<br />
display banners related to User interests (behavioral targeting / advertising);<br />
defense by the Website Owner, in court or in the stages leading to possible legal action, against improper use of the same or related services by the User.<br />
Some forms of processing (sending promotional information, commercial profiling, behavioral advertising, geolocation, etc.) may require the express consent of the User or the Interested Party. Specific information may be shown on the pages of the Site in connection with particular services or processing of Data provided by the User or by the Interested Party.</p>

<p>We do not store credit card details nor do we share customer details with any 3rd parties</p>
                        </div>
                    </div>
                </div> 
        </div>
    );
};

export default Privacy;
