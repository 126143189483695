import React, {useEffect, useState} from 'react';
import { Helmet } from "react-helmet";
import Slider from "react-slick";
import { useHistory, Link } from "react-router-dom";

import location from './../../api/stubs/locations.json';
import { validPostcode, formatPostcodeForCompare, isProduction } from './../../utils/helpers';
import { globalStateValue } from './../../store/globalState';
// import { useServices } from './../hooks/useServices';
// import { useHistory, Link } from "react-router-dom";
// import Slider from "react-slick";

import box from './../../theme/img/box.png';
import grey from './../../theme/img/gray.png';
import tech1 from './../../theme/img/img-1.png';
import tech2 from './../../theme/img/img-2.png';
import tech3 from './../../theme/img/img-3.png';
import tech4 from './../../theme/img/img-4.png';
import trust2 from './../../theme/img/stars.svg';
import tick1 from './../../theme/img/tik.svg';



const OrderHome = () => {
    const pageTitle = 'Exascale';
    const history = useHistory();
    const [, dispatch] = globalStateValue();
    const [postcode, setPostcode] = useState('');
    const [errors, setErrors] = useState(false);
    const [isWithinCoverage, setWithinCoverage] = useState('');
    const [isLoading, setLoading] = useState(false);

    

    const handlePostcodeChange = (inputValue) => {
        setPostcode(formatPostcodeForCompare(inputValue));
    }

    const handlePostcodeSubmission = () => {
        if(validPostcode(postcode)) {
            setErrors(false);
            const result = location.filter(coverage => formatPostcodeForCompare(coverage.postCode) === formatPostcodeForCompare(postcode)); 


            dispatch({
                type: 'UPDATE_CUSTOMER',
                customerData: {
                    postcode
                }
            });
            setLoading(true)
            setTimeout(() => { 
                setWithinCoverage(result.length === 0 ? 'No' : 'Yes');
            }, 1000);
        } else {
            setErrors(true)
        }
    }

    const handleEnter = (e) => {
        if (e.key === 'Enter') {
            handlePostcodeSubmission()
        }
    }

    useEffect(() => {
        if(isWithinCoverage === 'Yes') {
            history.push("/packages");
        }
        if(isWithinCoverage === 'No') {
            history.push("/register-interest");
        }      
     }, [isWithinCoverage])

    var sliderSettings = {
        centerMode: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        centerPadding: '45px',
        focusOnSelect: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              dots: true,
              initialSlide: 1,
              infinite: false
            }
          }
        ]
    }




   

    return (
        <div className="c-home">
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            {isLoading &&
                <div className="loading-mask">
                    <div className="loading-bar">
                        <div className="in"></div>
                    </div> 
                </div>   
            }

            <div class="hero-area pb-200">
                <div class="container-lg">
                    <div class="row">
                        <div class="col-12">
                            <div class="hero">
                                <h1>Enter your postcode to order your hyper speed broadband online</h1>
                                <div class="row row-spacing-b-20">
                                    <div class="col-12">
                                        <input type="text" className={`font1 lh14 tcenter ${errors ? 'form-error': ''}`}
                                            placeholder="Enter your postcode" 
                                            onChange={e => handlePostcodeChange(e.target.value)}
                                            onKeyPress={e => handleEnter(e)}
                                            /> 
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-12">
                                    <button className={`btn-default btn-large`} onClick={handlePostcodeSubmission}>
                                        Order Now
                                    </button>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="fast-area container-max-width">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="fast-bg flex align-center jcenter">
                                <img src={box} alt="box" />
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="fast-text">
                                <h2>Fast, strong, and reliable Gigabit broadband.</h2>
                                <p>Our broadband is powerful, with up to 1,000 MBPS download speed for
                                    the ultimate connection. Full fibre, installed in under 14 days, we offer a super
                                    fast alternative to the tradiational BT phone line. </p>
                                <img src={trust2} alt="trustpilot score" />
                                <p className="small-paragraph">Excellent | Trustscore 4.8 | 105 reviews</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="deliver-area">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="title tcenter">
                                <span>Exascale deliver</span>
                                <h3>A technology first approach to home internet
                                    packages</h3>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="deliver-single ">
                                <div class="deliver-img">
                                    <img src={tech1} alt="img" />
                                </div>
                                <h4>Streaming, gaming, or working</h4>
                                <p>Our ultra fast full fibre broadband reaches speeds up to 1,000 Mbps. And with no download limits, you’re guarenteed a constant, high speed connection.</p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="deliver-single ">
                                <div class="deliver-img">
                                    <img src={tech2} alt="img" />
                                </div>
                                <h4>Bye-bye buffering</h4>
                                <p>Watch Netflix, Amazon Prime, or iPlayer. Play the lastest online games. Or host meetings with Teams, Zoom, or Skype - with no delays. </p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="deliver-single ">
                                <div class="deliver-img">
                                    <img src={grey} alt="img" />
                                    <img src={tech3} alt="img" />
                                </div>
                                <h4>What is full fibre?</h4>
                                <p>Fibre optic cables have very little interference, which means your data can travel further and faster, increasing bandwidth and connection speeds.</p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="deliver-single ">
                                <div class="deliver-img">
                                    <img src={tech4} alt="img" />
                                </div>
                                <h4>Fully UK Support</h4>
                                <p>Our support teams are based right here in the heart of England. Our friendly and efficient staff are here to answer any questions you might have.</p>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        <div class="container-max-width">
            <div class="row">
                <div class="col-12 ">
                    <div class="title tcenter">
                        <span>We’re no more expensive than Sky or BT</span>
                        <h2>No hidden costs, broadband installed in 14 days.</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="pricing tcenter pricing-sldier"> 
                        <Slider {...sliderSettings}>
                        <div class="pricing-box">
                            <div class="pricing-top">
                                <span>Full Fibre 50</span>
                                <h2>£25.99</h2>
                                <h3>24 month contract</h3>
                                <span className="speed">50Mbps / <span className="small">50 Mbps</span></span>
                                <span className="package-heading">Average download / upload speed</span>
                            </div>
                            <div class="pricing-bottom">
                                <ul>
                                    <li>
                                        <img src={tick1} alt="tik" />
                                        <span>Truely unlimited downloads</span>
                                    </li>
                                    <li>
                                        <img src={tick1} alt="tik" />
                                        <span>FREE activation</span>
                                    </li>
                                    <li>
                                        <img src={tick1} alt="tik" />
                                        <span>FREE WiFi 6 Mesh Router</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="pricing-box">
                            <div class="pricing-top">
                                <span>Full Fibre 150</span>
                                <h2>£28.99</h2>
                                <h3>24 month contract</h3>
                                <span className="speed">150Mbps / <span className="small">150 Mbps</span></span>
                                <span className="package-heading">Average download / upload speed</span>
                            </div>
                            <div class="pricing-bottom">
                                <ul>
                                    <li>
                                        <img src={tick1} alt="tik" />
                                        <span>Truely unlimited downloads</span>
                                    </li>
                                    <li>
                                        <img src={tick1} alt="tik" />
                                        <span>FREE activation</span>
                                    </li>
                                    <li>
                                        <img src={tick1} alt="tik" />
                                        <span>FREE WiFi 6 Mesh Router</span>
                                    </li>
                                    
                                </ul>
                            </div>
                        </div>
                        <div class="pricing-box">
                            <div class="pricing-top">
                                <span>Full Fibre 500</span>
                                <h2>£38.99</h2>
                                <h3>24 month contract</h3>
                                <span className="speed">500Mbps / <span className="small">500 Mbps</span></span>
                                <span className="package-heading">Average download / upload speed</span>
                            </div>
                            <div class="pricing-bottom">
                                <ul>
                                    <li>
                                        <img src={tick1} alt="tik" />
                                        <span>Truely unlimited downloads</span>
                                    </li>
                                    <li>
                                        <img src={tick1} alt="tik" />
                                        <span>FREE activation</span>
                                    </li>
                                    <li>
                                        <img src={tick1} alt="tik" />
                                        <span>FREE WiFi 6 Mesh Router</span>
                                    </li>
                                    
                                </ul>
                            </div>
                        </div>
                        <div class="pricing-box">
                            <div class="pricing-top">
                                <span>Full Fibre 1000</span>
                                <h2>£48.99</h2>
                                <h3>24 month contract</h3>
                                <span className="speed">900Mbps / <span className="small">900 Mbps</span></span>
                                <span className="package-heading">Average download / upload speed</span>
                            </div>
                            <div class="pricing-bottom">
                                <ul>
                                    <li>
                                        <img src={tick1} alt="tik" />
                                        <span>Truely unlimited downloads</span>
                                    </li>
                                    <li>
                                        <img src={tick1} alt="tik" />
                                        <span>FREE activation</span>
                                    </li>
                                    <li>
                                        <img src={tick1} alt="tik" />
                                        <span>FREE WiFi 6 Mesh Router</span>
                                    </li>
                                    
                                </ul>
                            </div>
                        </div>
                        </Slider>
                        </div>
                        
                    </div>
                    
             
            </div>
        </div>
        <div class="container-lg order-header">
                    <div class="row">
                        <div class="col-12">
                            <div class="hero">
                                <div class="row row-spacing-b-20">
                                    <div class="col-12">
                                        <input type="text" class="font1 lh14 tcenter"
                                            placeholder="Enter your postcode" 
                                            onChange={e => handlePostcodeChange(e.target.value)}
                                            onKeyPress={e => handleEnter(e)}
                                            /> 
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-12">
                                    <button className="btn-default btn-large" onClick={handlePostcodeSubmission}>
                                        Order Now
                                    </button>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


        </div>
    )
}

export default OrderHome;
  