import React from 'react';

// Components
import {Helmet} from "react-helmet";

const TermsOfService = () => {
    const pageTitle = 'Terms of Service';
    return (
        <div className={'c-terms c-content-page'}>
            <Helmet>
                <title>{pageTitle}</title>
                
            </Helmet>

            <div class="container-lg">
                    <div class="row">
                        <div class="col-12">
                            <h1>Terms of Service</h1>

                            <p>We've tried to make it as easy as possible to understand, but if you have any questions feel free to contact us with any questions.</p>
                            <p>This is a living document, we ‘Exascale’ reserve the right to make amendment or an explicit change to this document at anytime, without any prior notice.</p>

                            <p>Exascale (Exascale Limited) reserve the right to refuse or withhold a service and/or access to its services to a beneficiary (paying customer) or a third party. Exascale Ltd deem any content of an unlawful nature to be a direct breach of their terms of service, such content may include but not exclude; Adult material, malicious files i.e. files with the intention to infiltrate remote/local computer systems.</p>

                            <h2>CUSTOMER’S AGE</h2>
<p>All customers must be of the age of at least 18 years of age or at least of legal age for the country of residency, demanding a higher legal age by law, or that either their parent or legal guardian will act as the “Customer” in accordance with the terms stated in this contract.</p>

<h2>INTRODUCTORY OFFERS</h2>
<p>These offers are only valid for Web Hosting, Reseller Hosting and Cloud Hosting. Any discounts are deducted from your first month only. Introductory offers are only valid for new customers and returning customers whom of which have not been a previous customer within the last 6 months.</p>

<h2>EXCESSIVE DOWNLOAD CONTENT OR NON-LINKED CONTENT</h2>
<p>Refusal of service based on content matching either of these two criteria is entirely at the discretion of Exascale (Exascale Ltd). We reserve the right to suspend or throttle your account without any prior notice.</p>

<h2>SUPPORT</h2>
<p>Exascale will endeavor to provide a continuous high quality service. If you are to experience a problematic service, you should either consult the client portal as stated in your introductory email or contact us via telephone or email. (Such details can be found on your latest invoice).</p>

<h2>MAINTENANCE AND SCHEDULED DOWNTIME</h2>
<p>Exascale will make all customers or third parties aware in advanced of any maintenance windows or scheduled downtime 3 days in advanced.</p>

<h2>BANDWIDTH POLICY</h2>
<p>Exascale reserves the right to withhold/suspend your service(s) if the monthly bandwidth allowance is breached, you will be made aware when you are approaching the monthly
bandwidth allowance prior to your service being suspended. Customers who are suspended regularly are advised to contact the sales department to arrange or negotiate a bandwidth allowance increase.</p>

<h2>DISK ALLOCATION</h2>
<p>Hosting packages, virtual servers and dedicated servers are allocated physical disk space which is expressed in either Mb (Megabytes), Gb (Gigabytes) or Tb (Terabytes).
Shared hosting services (Web Hosting and Reseller Hosting) have soft limits restricted by the package purchased. Overuse of disk space will eventually lead to account suspension.</p>

<h2>EXCESSIVE RESOURCE USAGE</h2>
<p>Resources may include but may not exclude bandwidth, processor utilization, disk usage, or RAM memory usage. Exascale (Exascale Ltd) may implement calming measures to reduce
the affect of excessive usage at its own desecration on all  platforms.</p>

<h2>BACKUPS &amp; DATA LOSS</h2>
<p>Exascale (Exascale Ltd) do not backup or maintain a working copy of any data stored on our platforms or network. All our services are used solely at your own risk. This may include but not directly exclude; data corruption, hardware failure or an act of god. You therefore agree to take full responsibility for all data stored or transferred to our network or servers, and as a subsequence affect agree to take regular scheduled backups.</p>

<h2>SERVER COLOCATION</h2>
<p>All Colocation packages are for ONE server/machine only. Exascale reserve the right to invoice the customer for the amount of which it will cost to return the server(s) should their equipment arrive at the Data Centre in a way that is seen unfit or unsafe by our engineer’s. Customer’s take full responsibility for hardware/software failure and agree that it is the responsibility of the customer to replace, however, Exascale can replace/repair hardware for a fee, upon which will be applied to your account.</p>

<h2>DEDICATED SERVERS</h2>
<p>Exascale from time to time may audit the use of the Dedicated Server without any prior warning. Exascale DO NOT backup any dedicated servers unless otherwise stated on a per contract basis. Dedicated servers are leased to client and remain the property of Exascale Limited indefinitely.</p>

<h2>SHARED WEB HOSTING</h2>
<p>All accounts are to be used by the primary owner only and do not allow the holders to resell, store or give away web-hosting services of their website to other parties unless the account is intended for reselling. Web hosting services are defined as allowing a separate, third party to host content on the owner’s web site. Exceptions to this include ad banners, classified ads,
and personal ads.</p>

<h2>RESELLER WEB HOSTING</h2>
<p>Resellers are responsible for supporting their customer. We do not provide support to our Reseller’s customers. If a resellers customer contacts us, we reserve the right to place the customer account on hold until the reseller can assume their responsibility for their customer. All support requests must be made by the reseller on their customers’ behalf for security purposes.</p>

<p>Resellers are also responsible for all content stored or transmitted under their reseller account and the actions of their customers’. Exascale (Exascale Ltd) will hold any reseller responsible for any of their customers actions that violate the law or the terms of service.</p>

<h2>PAYMENT POLICIES</h2>
<p>All accounts are set up on a prepay basis. Exascale (Exascale Ltd) reserves the right to amend prices of accounts or services at any time. All pricing is guaranteed for the period of prepayment. Payment is due every 30, 90, 182 or 365 days, following the initial date of contract or when established. Customers will be sent a reminder to renew their service(s) near the end of their prepay period. Exascale (Exascale Ltd) reserves the right to suspend or cancel a service when an account shows arrears up to or beyond one payment.</p>

<p>The customer is responsible for all monies owed on the account from the time it was established. All invoices are sent directly to the customer via email shortly after the
online purchase has been made.</p>

<h2>CANCELLATION AND REFUNDS</h2>
<p>Exascale reserves the right to cancel the customers’ service at any time. In such event the customer will be entitled to a pro-rata credit based upon the remaining period of subscription/contractual period. The customer will not receive any extra monies or compensation for cancellation of services.</p>

<p>If a customer contravenes Exascale terms of service a refund will not be issued in the event of a cancellation. Customers may cancel their account at any time outside of their contractual period, with at least 90 days notice.</p>

<p>Any incentives offered to customers when opening their account will also be removed. Fees charged on a prepay basis are non-refundable. In addition some accounts incur set-up fees, these charges are also non-refundable.</p>

<h2>DISCLAIMER</h2>
<p>Exascale are not be responsible for any damages your business may suffer as a direct result or involvement of Exascale. Exascale provides no warranties of any kind, expressed or implied for services we provide. Exascale reserves the right to revise or alter its policies at any point in time. Should Exascale have legal action taken against them, for any reason specified in any of our policies or otherwise you agree to only take us to court in the country in which our business and servers reside.</p>

<p>You agree with and are bound by Exascale’s Terms and Conditions upon purchase of any service provided by Exascale Limited.</p>
                        </div>
                    </div>
            </div>
        </div>
    );
};

export default TermsOfService;
