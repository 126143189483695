import React, { Component } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import ReactDOM from 'react-dom';
import './theme/scss/app.scss';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import TagManager from 'react-gtm-module';
// import CookieConsent from 'react-cookie-consent';

// Store
import { Provider } from './store/globalState';
import reducer, { initialState } from "./store/reducer";

// Components
// import Header from './components/Header';
import OrderHeader from './components/Header/orderHeader';
import Footer from './components/Footer';
// import MobileNav from './components/MobileNav';
// import Menu from './components/Menu';
import PrivateRoute from "./components/PrivateRoute";

// Routes
import Error from './routes/error';
import Home from './routes/home';
import PropertyDevelopers from './routes/property-developers';
import WhyExascale from './routes/why-exascale';
import ContactUs from './routes/contact-us';
import CheckAvailability from './routes/check-availability';
import RegisterInterestOne from './routes/not-available/sign-up-1';
import RegisterInterestTwo from './routes/not-available/sign-up-2';
import RegisterInterestThree from './routes/not-available/sign-up-3';
import Packages from './routes/available/packages';
import Checkout from './routes/available/checkout';
import CheckoutThanks from './routes/available/checkout-thanks';
import NotFound from './routes/notFound';
import Register from './routes/account/register';
import Login from './routes/account/login';
import ForgotPassword from './routes/account/forgotPassword';
import ResetPassword from './routes/account/passwordReset';
import Cookies from './routes/cookies';
import Privacy from './routes/privacy';
import Legal from './routes/legal';
import Complaints from './routes/complaints';
import ServiceLevel from './routes/services-level';
import TermsOfService from './routes/terms-of-service';
import RegistrationSuccess from './routes/account/registrationSuccess';
import AccountAuth from './routes/account/account-auth';
import Success from './routes/account/success';

// Order Routes
import OrderHome from './routes/simpleOrder/orderHome';
import OrderNotAvailable from './routes/simpleOrder/orderNotAvailable';
import OrderPackages from './routes/simpleOrder/orderPackages';


// Utils
import GA from './utils/googleAnalytics';
import ScrollToTop from './utils/scrollToTop';

const firebaseConfig = {
  apiKey: "AIzaSyDRhnCROHGAuevjej0hofTV0_YwY_g7rxU",
  authDomain: "exascale-retail.firebaseapp.com",
  databaseURL: "https://exascale-retail.firebaseio.com",
  projectId: "exascale-retail",
  storageBucket: "exascale-retail.appspot.com",
  messagingSenderId: "561974760581",
  appId: "1:561974760581:web:61b7ada0f83a955bd505ec",
  measurementId: "G-R1PTWFJD9Z"
};
firebase.initializeApp(firebaseConfig);

const tagManagerArgs = {
    gtmId: ''
}

// Go to Firebase and get store data

class App extends Component {

  render () {
    return (
      <Provider initialState={initialState} reducer={reducer}>
        <BrowserRouter>
        <ScrollToTop />
          { GA.init() && <GA.RouteTracker /> }
          <main id="main" className="panel">
            <div className="slideoutJSPanel" data-slideout-ignore="true">
              <OrderHeader />
                <Switch>
                  <Route exact path='/' component={OrderHome} />

                  {/* <Route path='/property-developers' component={PropertyDevelopers} /> */}
                  {/* <Route path='/why-exascale' component={WhyExascale} /> */}
                  <Route path='/contact-us' component={ContactUs} />
                  {/* <Route path='/check-availability' component={CheckAvailability} /> */}
                  <Route path='/register-interest' component={RegisterInterestOne} />
                  <Route path='/register-interest-part-2' component={RegisterInterestTwo} />
                  <Route path='/register-interest-complete' component={RegisterInterestThree} />
                  {/* <Route exact path='/not-available' component={RegisterInterestOne} /> */}
                  <Route exact path='/packages' component={OrderPackages} />
                  <Route exact path='/checkout' component={Checkout} />
                  <Route exact path='/checkout-thanks' component={CheckoutThanks} />

                  <Route exact path='/cookie-policy' component={Cookies} />
                  <Route exact path='/legal' component={Legal} />
                  <Route exact path='/privacy-policy' component={Privacy} />
                  <Route exact path='/complaints-procedure' component={Complaints} />
                  <Route exact path='/service-level-agreement' component={ServiceLevel} />
                  <Route exact path='/terms-of-service' component={TermsOfService} />

                  
                  {/* <PrivateRoute exact path='/post' component={Post} /> */}
                  
                  {/* <PrivateRoute path='/brand/:url_key' component={Brand}
                    render={(props) => {
                      return (
                        <Brand {...props}/>
                      )
                    }
                    }
                  /> */}
                  {/* <PrivateRoute exact path='/order-success' component={Success} /> */}
                  <Route path='/error' component={Error} />
                  {/* <Route path='/register' component={Register} /> */}
                  <Route path='/login' component={Login} />
                  {/* <Route path='/forgot-password' component={ForgotPassword} /> */}
                  {/* <Route path='/registration-success' component={RegistrationSuccess} /> */}
                  {/* <Route path='/reset-password' component={ResetPassword} /> */}
                  <PrivateRoute path='/manage' component={AccountAuth} />
                  <Route component={NotFound} />
                </Switch>
              <Footer />
            </div>
          </main>
          {/* <CookieConsent disableStyles={true}
                         containerClasses="c-cookie__wrapper"
                         buttonClasses="btn btn-branded"
                         contentClasses="c-cookie__wrapper__text">
              This website is operated by H. Young (Operations) Limited. It uses cookies and other similar technologies to collect information about how it is used and what users’ preferences are. This information helps us provide users with a better browsing experience, improve how our website operates and the product selection and services available through it, and deliver targeted advertising and marketing that is more relevant to our users’ interests. For more information, see our Cookie Policy.
              We require your consent to set certain non-essential cookies on your device. By continuing to browse our website without changing your cookie preferences, you consent to us setting cookies on your device. You can withdraw your consent at any time by changing your cookie preferences. Our Cookie Policy explains how you can do this.
          </CookieConsent> */}
        </BrowserRouter>
      </Provider>
    )
  }
}


TagManager.initialize(tagManagerArgs);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
