import { useState, useEffect } from 'react';
import firebase from 'firebase';
import { isProduction } from '../utils/helpers'

export function useAuth() {
  const { auth } = firebase;
  const [authState, setAuthState] = useState({
    loggedIn: null,
    pending: true,
    user: null,
  })



  const doCreateUserWithEmailAndPassword = (email, password, accountNumber, storeName, accountValid) =>
    firebase.auth().createUserWithEmailAndPassword(email, password)
    .then((userCredential) => {
      const user = userCredential.user;

      firebase.firestore().collection(isProduction ? 'users' : 'users')
      .add({
        uid: user.uid,
        email: user.email,
        isActive: false,
        lastLoggedIn: new Date()
      })
    })
    .catch((error) => {
      return error
    });


  const doSignInWithEmailAndPassword = (email, password) => 
    firebase.auth().signInWithEmailAndPassword(email, password)
    
  
  const doPasswordReset = email => firebase.auth().sendPasswordResetEmail(email);

  const doVerifyResetCode = (code) => firebase.auth().verifyPasswordResetCode(code);

  const doPasswordUpdate = (code, password) => firebase.auth().confirmPasswordReset(code, password);
  
  const doSignOut = () => {
    firebase.auth().signOut();
    window.location.replace("/");
  }


  const doUser = uid => firebase.firestore().collection(isProduction ? 'customers' : 'staging_customers').get();
 
  const doUsers = () => firebase.firestore().collection(isProduction ? 'users' : 'users');

  const doCustomers = () => firebase.firestore().collection(isProduction ? 'customers' : 'staging_customers');

  const doLeads = () => firebase.firestore().collection(isProduction ? 'lead' : 'staging_lead');

  const doUserFind = (prop, condition) => firebase.firestore().collection(isProduction ? 'customers' : 'staging_customers')
  .where(prop, "==", condition).get();


  const doStagingUsers = (docData) => {
     firebase.firestore().collection('staging_users')
      .add(docData)
  }

  const doMandateUpdate = (uid, mandateId) => {
    firebase.firestore().collection(isProduction ? 'customers' : 'staging_customers')
    .where('uid', "==", uid).get()
    .then(
      (querySnapshot) => {
        querySnapshot.forEach((document) => {
          document.ref.update({
            mandateId
          })
         });
      });
  }

  const doInitialPaidUpdate = (uid) => {
    firebase.firestore().collection(isProduction ? 'customers' : 'staging_customers')
    .where('uid', "==", uid).get()
    .then(
      (querySnapshot) => {
        querySnapshot.forEach((document) => {
          document.ref.update({
            initialInvoice: true
          })
         });
      });
  }

  const doActivateDate = (uid, activationDate) => {
    firebase.firestore().collection(isProduction ? 'customers' : 'staging_customers')
    .where('uid', "==", uid).get()
    .then(
      (querySnapshot) => {
        querySnapshot.forEach((document) => {
          document.ref.update({
            activationDate
          })
         });
      });
  }

  const doRecordUpdate = (uid, status) => {
    firebase.firestore().collection(isProduction ? 'customers' : 'staging_customers')
    .where('uid', "==", uid).get()
    .then(
      (querySnapshot) => {
        querySnapshot.forEach((document) => {
          document.ref.update({
            orderStatus: status
          })
         });
      });
  }

  const doMassRecordUpdate = (records, status) => {
    // eslint-disable-next-line
    records.map((item) => {
        const userDocData = item.data();
        const {email} = userDocData;
        firebase.firestore().collection(isProduction ? 'customers' : 'staging_customers')
        .where('email', "==", email).get()
        .then(
          (querySnapshot) => {
            querySnapshot.forEach((document) => {
              document.ref.update({
                isActive: status
              })
            });
          });
    })

  }

  const doLastLoggedIn = email => {
    firebase.firestore().collection(isProduction ? 'customers' : 'staging_customers')
    .where('email', "==", email).get()
    .then(
      (querySnapshot) => {
        querySnapshot.forEach((document) => {
          document.ref.update({
            lastLoggedIn: new Date()
          })
         });
      });
  }

  

  useEffect(() => {
    const unregisterAuthObserver = auth().onAuthStateChanged(user => {
      setAuthState({ user, pending: false, loggedIn: !!user })
    })
    return () => unregisterAuthObserver()
    // eslint-disable-next-line
  }, [])

  return { 
    doSignOut,
    doSignInWithEmailAndPassword,
    doCreateUserWithEmailAndPassword,
    doPasswordReset,
    doVerifyResetCode,
    doPasswordUpdate,
    doUser,
    doUsers,
    doCustomers,
    doStagingUsers,
    doUserFind,
    doRecordUpdate,
    doMandateUpdate,
    doActivateDate,
    doMassRecordUpdate,
    doLastLoggedIn,
    doLeads,
    doInitialPaidUpdate,
    auth, 
    ...authState }
}

