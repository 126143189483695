import React from 'react';
import { Link } from "react-router-dom";

import fLogo from  './../../theme/img/f-logo.svg';
import facebook from './../../theme/img/Facebook_White.svg';
import twitter from './../../theme/img/Instagram_White.svg';
import instagram from './../../theme/img/Twitter_White.svg';

const Footer = () => {
    return (
        <>
        <footer className="footer-area">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="footer container-max-width">
                                <div className="footer-left">
                                    <ul>
                                        <li><Link to="/"><img src={fLogo} alt="logo" /></Link></li>
                                        <li><Link to="/legal">Legal</Link></li>
                                        <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                        <li><Link to="/contact-us">Contact</Link></li>
                                        <li><a href="https://comparefibre.co.uk/" target="_blank">Compare Fibre</a></li>
                                    </ul>
                                </div>
                                <div className="footer-right">
                                    <ul>
                                        <li><a href="https://www.facebook.com/ExascaleUK/" target="_blank" ><img src={facebook} alt="facebook" /></a>
                                        </li>
                                        <li><a href="https://www.instagram.com/exascaleuk" target="_blank"><img src={instagram} alt="Instagram" /></a>
                                        </li>
                                        <li><a href="https://twitter.com/exascaleuk" target="_blank"><img src={twitter} alt="Twitter" /></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        <div className="footer-mobile-area">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="footer-mobile">
                                <div className="mobile-social">
                                    <ul>
                                        <li><a href="https://www.facebook.com/ExascaleUK/" target="_blank"><img src={facebook} alt="facebook" /></a>
                                        </li>
                                        <li><a href="https://www.instagram.com/exascaleuk" target="_blank"><img src={twitter} alt="Twitter" /></a>
                                        </li>
                                        <li><a href="https://twitter.com/exascaleuk" target="_blank"><img src={instagram} alt="Instagram" /></a></li>
                                    </ul>
                                </div>
                                <div className="footer-links">
                                    <ul>
                                        <li><Link to="/legal">Legal</Link></li>
                                        <li><a href="https://comparefibre.co.uk/" target="_blank">Compare Fibre</a></li>
                                    </ul>
                                    <ul>
                                        <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                        <li><Link to="/contact-us">Contact</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Footer;
  