import React, {useEffect, useState} from 'react'
import { Redirect, Route } from 'react-router-dom';
import { useAuth } from './../../hooks/useAuth';


const PrivateRoute = ({ component: Component, ...rest }) => {
    const { loggedIn, user, doUsers, doLastLoggedIn } = useAuth();
    const [active, setActive] = useState(null);
    const [, setUserUid] = useState('');
    const [, setValid] = useState(null);
    const twelveHour = 18;

    useEffect(() => {

        if(user && user.uid) {
            doUsers().get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((userDoc) => {

                        const userDocData = userDoc.data()
                        const { accountValid, isAdmin, uid, lastLoggedIn, email } = userDocData;



                        if(uid === user.uid) {
                            setActive(isAdmin);
                            setValid(accountValid);
                            setUserUid(uid);

                            if(lastLoggedIn) {
                                const date = new Date(lastLoggedIn.seconds * 1000);
                                const timeDiff = Math.abs(new Date() - new Date(date)) / 36e5;

                                if((timeDiff > twelveHour && timeDiff !== 0)) {
                                    doLastLoggedIn(email)
                                }
                            } else {
                                doLastLoggedIn(email)
                            }
                        }

                    })
                })
                .catch(error => {
                    console.log(error)
                });
        } else if (user && !user.id){
            setActive(false);
        }
        // eslint-disable-next-line
    }, [user,loggedIn])

    return (
        <Route
            {...rest}
            render={props => 
                loggedIn !== null && active !== null ?
                    loggedIn && active ? (
                        <Route exact path={props.match.url} component={Component} {...props} />
                    ) : (
                        <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                    ) :
                    loggedIn === false && active === null ?
                        <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                        :
                        false
            }
        />
    )
}

export default PrivateRoute