import React, {useState, useEffect} from 'react';
import {Helmet} from "react-helmet";
import { hostName } from './../../utils/helpers';
import { useAuth } from '../../hooks/useAuth';


import logo from './../../theme/img/logo.svg';
import './../../theme/admin/bootstrap/css/bootstrap.scss';
import './../../theme/admin/bootstrap/css/bootstrap-theme.scss';
import './../../theme/admin/modern.scss';
import './../../theme/admin/custom.scss';

const AccountAuth = () => {
    const pageTitle = 'Admin Account Management';
    const [email, setEmail] = useState('');
    const [, setError] = useState(null);
    const [account, setAccount] = useState('');
    const [records, setRecords] = useState([]);
    const [leads, setLeads] = useState([]);
    const [update, setUpdate] = useState(false)
    const { doUserFind, doRecordUpdate, doActivateDate, doMassRecordUpdate, user, doUsers, doCustomers, doLeads, doMandateUpdate, doInitialPaidUpdate } = useAuth();
    const isInvalid = false;

    useEffect(() => {
        doCustomers().get()
            .then((querySnapshot) => {
                setRecords(querySnapshot.docs)
            })
            .catch(error => {
                console.log(error)
            });

        doLeads().get()
            .then((querySnapshot) => {
                setLeads(querySnapshot.docs)
            })
            .catch(error => {
                console.log(error)
        });
        // eslint-disable-next-line
    }, [])




    const toggleSingleAccountStatus = (uid, status) => {
        doRecordUpdate(uid, status)
        setUpdate(true);
    }

    const toggleSingleActivationDate = (uid, date) => {
        doActivateDate(uid, date)
        setUpdate(true);
    }

    const convertToCSV = (objArray) => {
        var arr = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
        var str =
          `${Object.keys(arr[0])
            .map((value) => `"${value}"`)
            // eslint-disable-next-line
            .join(',')}` + '\r\n';
        var csvContent = arr.reduce((st, next) => {
          st +=
            `${Object.values(next)
              .map((value) => `"${value}"`)
              // eslint-disable-next-line
              .join(',')}` + '\r\n';
          return st;
        }, str);
        var element = document.createElement('a');
        element.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvContent);
        element.target = '_blank';
        element.download = 'allCustomerRecords.csv';
        element.click();
    }



    const exportAllRecords = () => {
        let records = [];


        doCustomers().get()
            .then((querySnapshot) => {
                querySnapshot.forEach((userDoc) => {
                    
                    const userDocData = userDoc.data();
                    const { firstName,
                        lastName,
                        addressLineOne,
                        addressLineTwo,
                        postCode,
                        email: searchEmail,
                        telephone,
                        plan,
                        orderStatus } = userDocData

                    const payload = {
                        firstName,
                        lastName,
                        addressLineOne,
                        addressLineTwo,
                        postCode,
                        searchEmail,
                        telephone,
                        plan,
                        orderStatus,
                        orderedDate: userDocData?.lastLoggedIn ? new Date(userDocData?.lastLoggedIn?.seconds * 1000).toLocaleString("en-GB").replace(',', '') : ''
                    }

                    records.push(payload);
                })
                if(records.length > 0) {
                    convertToCSV(records);
                }
            })

    }

    const getUseableRecords = () => {
        const users = []

        records.map((userDoc, index) => {
            const userDocData = userDoc.data();
            users.push(userDocData)
        });

        return users;
    }

    const getPrice = (plan) => {
        switch(plan) {
            case 'fibre80':
                return '2599';
            case 'fibre150':
                return '2899';
            case 'fibre400':
                return '5500';
            case 'fibre500':
                return '3899';
            case 'fibre900':
                return '4899';
        }
    }


    const submitToGoCardLess = (uid) => {
        // console.log(records)
        const useableRecords = getUseableRecords();
        const currentRecord = useableRecords.filter(record => record.uid === uid);


        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(currentRecord[0])
        };

        fetch(`${hostName()}/customerSubscriptSignUp`, requestOptions)
        .then(response => {
            if(response.ok) {
                response.json().then(data => {
                    // do something with your data
                    console.log(data)

                    doMandateUpdate(uid, data.id)
                  });
            }
        })
    }

    const raiseInitialInvoice = (uid) => {
        const useableRecords = getUseableRecords();
        const currentRecord = useableRecords.filter(record => record.uid === uid);
        const { postCode, accountName, mandateId, plan } = currentRecord[0];
        const planPrice = getPrice(plan);
        const date = new Date();
        const day = date.getDate();


        const expectedPrice = (Number(planPrice) / 31) * day;


        const payload = {
            postCode,
            accountName,
            mandateId,
            price: expectedPrice.toFixed(0),
            todaysDate: `${date.getFullYear()}-${('0' + (date.getMonth()+1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`
        }


        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload)
        };

        fetch(`${hostName()}/initialPayment`, requestOptions)
        .then(response => {
            if(response.ok) {
                response.json().then(data => {
        
                    console.log(data)
                  });
            }
        })


    }

    const raisedBackDatedInvoive = (uid) => {
        const useableRecords = getUseableRecords();
        const currentRecord = useableRecords.filter(record => record.uid === uid);
        const { postCode, accountName, mandateId, plan,  activationDate} = currentRecord[0];
        const planPrice = getPrice(plan);
        const date = new Date();
        const day = date.getDate();


        const expectedPrice = (Number(planPrice) / 30) * day;

        const pricePerDay = ((Number(planPrice) / 30)) / 100;

        const backInvoice = parseInt(daysActive(activationDate)) * parseFloat(pricePerDay.toFixed(2))

        const backInvoiceInPence = backInvoice.toFixed(2) * 100;

        const payload = {
            postCode,
            accountName,
            mandateId,
            price: backInvoiceInPence,
            todaysDate: `${date.getFullYear()}-${('0' + (date.getMonth()+1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`
        }


        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload)
        };

        fetch(`${hostName()}/initialPayment`, requestOptions)
        .then(response => {
            if(response.ok) {
                response.json().then(data => {
        
                    console.log(data)
                    doInitialPaidUpdate(uid)
                  });
            }
        })


    }

    const startMontlhyBilling = (uid) => {
        const useableRecords = getUseableRecords();
        const currentRecord = useableRecords.filter(record => record.uid === uid);

        const { postCode, accountName, mandateId, plan } = currentRecord[0];



        const payload = {
            postCode,
            accountName,
            mandateId,
            price: getPrice(plan)
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload)
        };

        fetch(`${hostName()}/activateCustomer`, requestOptions)
        .then(response => {
            if(response.ok) {
                response.json().then(data => {
        
                    console.log(data)
                  });
            }
        })

    }

    const daysActive = (activateDate) => {
        const activeDate = new Date(activateDate);
        const today = new Date("2021-09-30");

        const differenceInTime = today.getTime() - activeDate.getTime();
  
        const days = differenceInTime / (1000 * 3600 * 24);

        return days
    }
    


    return (
        <div>
            <Helmet>
                <title>{pageTitle}</title>
                <body className="is-admin-area" />
            </Helmet>

            { update && <div class="alert alert-info" role="alert">You have updated records, refresh to see the latest else do not clear the cache until all records updated</div>}
            <div class="page-inner">
                <div className="page-title">
                    <h3><img src={logo} alt="exascale-logo" /></h3>
                    <div className="page-breadcrumb">
                        <ol className="breadcrumb">
                            <li class="active">Dashboard</li>
                        </ol>
                    </div>
                </div>
                <div id="main-wrapper">
                    <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="panel info-box panel-white">
                                <div class="panel-body">
                                    <div class="info-box-stats">
                                        <p class="counter">0</p>
                                        <span class="info-box-title">Customer sign-up this month</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="panel info-box panel-white">
                                <div class="panel-body">
                                    <div class="info-box-stats">
                                        <p class="counter">£0.00</p>
                                        <span class="info-box-title">Estimated current month revenue</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="panel info-box panel-white">
                                <div class="panel-body">
                                    <div class="info-box-stats">
                                        <p class="counter">0</p>
                                        <span class="info-box-title">Install completed this month</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="panel info-box panel-white">
                                <div class="panel-body">
                                    <div class="info-box-stats">
                                        <p class="counter">Lichfield</p>
                                        <span class="info-box-title">Trending lead location</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                    <div class="col-lg-12 col-md-12">
                            <div class="panel panel-white">
                                <div class="panel-heading">
                                    <h4 class="panel-title">All Customers <span className="btn btn-default" onClick={exportAllRecords}>Export all {records.length} customers</span></h4>
                                </div>
                                <div class="panel-body">
                                    <div class="table-responsive project-stats">  
                                       <table class="table">
                                           <thead>
                                               <tr>
                                               <th scope="col">#</th>
                                                <th scope="col">First Name</th>
                                                <th scope="col">Last Name</th>
                                                <th scope="col">Address Line 1</th>
                                                <th scope="col">Address Line 2</th>
                                                <th scope="col">Postcode</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Telephone</th>
                                                <th scope="col">Plan</th>
                                                <th scope="col">Order Status</th>
                                                <th scope="col">Activation Date</th>
                                                <th scope="col">Actions</th>
                                                <th scope="col"></th>
                                               </tr>
                                           </thead>
                                           <tbody>
                                           {records.length > 0 &&
                                    records.map((userDoc, index) => {
                                        const userDocData = userDoc.data();
                                        const {uid, firstName, lastName, addressLineOne, addressLineTwo, postCode, email:searchEmail, telephone, plan, orderStatus, mandateId, activationDate, initialInvoice} = userDocData;
                                        const planPrice = getPrice(plan);
                                        const date = new Date();
                                        const day = date.getDate();

                                        const expectedPrice = ((Number(planPrice) / 31) * day) / 100;
                                        const pricePerDay = ((Number(planPrice) / 30)) / 100;

                                        const backInvoice = parseInt(daysActive(activationDate)) * parseFloat(pricePerDay.toFixed(2))
                                        


                                        return (
                                            <tr key={uid}>
                                                <th scope="row">{}</th>
                                                <td>{firstName}</td>
                                                <td>{lastName}</td>
                                                <td>{addressLineOne}</td>
                                                <td>{addressLineTwo}</td>
                                                <td>{postCode}</td>
                                                <td>{searchEmail}</td>
                                                <td>{telephone}</td>
                                                <td>{plan}</td>
                                                <td>{orderStatus}</td>
                                                {activationDate ? <td>{activationDate}</td> : <td><input onChange={(e) => toggleSingleActivationDate(uid, e.target.value)} type="date"></input></td>}
                                                <td>
                                                    
                                                <div class="form-group btn-form-group">
                                                    <select className="form-control" onChange={(e) => toggleSingleAccountStatus(uid, e.target.value)}>
                                                        <option>Change Status</option>
                                                        <option>Service Order Placed</option>
                                                        <option>Awaiting Build</option>
                                                        <option>UG Rod &amp; Rope</option>
                                                        <option>UG Rod &amp; Successful</option>
                                                        <option>First Fix Cabling</option>
                                                        <option>CSP Installed</option>
                                                        <option>Light Test Successful</option>
                                                        <option>Ready for Fit &amp; Test</option>
                                                        <option>Fit and Test Booked</option>
                                                        <option>First and Test Successful</option>
                                                        <option>On Hold</option>
                                                        <option>Service Active</option>
                                                    </select>
                                                    </div>
                                                </td>
                                                <td>
                                                    {!mandateId  && <><a className="label label-info" onClick={() => submitToGoCardLess(uid)}>Submit to GoCardless</a><br /></>} 
                                                    {activationDate && <>Days active: {daysActive(activationDate)}<br /></>}
                                                    {activationDate && <>Price per day: £{pricePerDay.toFixed(2)}<br /></>} 
                                                    {orderStatus !== 'On Hold' && mandateId && activationDate && !initialInvoice && <><a className="label label-danger" onClick={() => raisedBackDatedInvoive(uid)}>Raise Backdated Invoice (to 30/09/2021) of £{backInvoice.toFixed(2)}</a><br /></>}
                                                    
                                                    {/* {mandateId && <a className="label label-danger" onClick={() => raiseInitialInvoice(uid)}>Raise Initial Invoice of £{expectedPrice.toFixed(2)}</a>} */}
                                                    {mandateId && <a className="label label-primary" onClick={() => startMontlhyBilling(uid)}>Start Monthly Billing Billing - £{(planPrice / 100).toFixed(2)}</a>}
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                               

                                           </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                    <div class="col-lg-12 col-md-12">
                            <div class="panel panel-white">
                                <div class="panel-heading">
                                    <h4 class="panel-title">All Leads ({leads.length})</h4>
                                </div>
                                <div class="panel-body">
                                    <div class="table-responsive project-stats">  
                                       <table class="table">
                                           <thead>
                                               <tr>
                                               <th scope="col">#</th>
                                                <th scope="col">First Name</th>
                                                <th scope="col">Last Name</th>
                                                <th scope="col">Address Line 1</th>
                                                <th scope="col">Address Line 2</th>
                                                <th scope="col">Postcode</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Telephone</th>
                                                <th scope="col">Current Provider</th>
                                               </tr>
                                           </thead>
                                           <tbody>
                                           {leads.length > 0 &&
                                    leads.map((userDoc, index) => {
                                        const userDocData = userDoc.data();
                                        const {uid, firstName, lastName, addressLineOne, addressLineTwo, postcode, email:searchEmail, telephone, provider} = userDocData;
                                        return (
                                            <tr key={uid}>
                                                <th scope="row">{}</th>
                                                <td>{firstName}</td>
                                                <td>{lastName}</td>
                                                <td>{addressLineOne}</td>
                                                <td>{addressLineTwo}</td>
                                                <td>{postcode}</td>
                                                <td>{searchEmail}</td>
                                                <td>{telephone}</td>
                                                <td>{provider}</td>
                                            </tr>
                                        )
                                    })
                                }
                                               
                                           </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            
        </div>
    );
};

export default AccountAuth;
