import React from 'react';

// Components
import {Helmet} from "react-helmet";

const ServiceLevel = () => {
    const pageTitle = 'Service Level Agreement';
    return (
        <div className={'c-legal c-content-page'}>
            <Helmet>
                <title>{pageTitle}</title>
                
            </Helmet>

            <div class="container-lg">
                    <div class="row">
                        <div class="col-12">
                            <h1>Service Level Agreement</h1>

                            <p>All of our services are guaranteed by an 99.9% SLA, which outlines our commitment to you as customer, defining the levels of service you can expect, and what compensation we will provide in the unlikely event of failure on our part to maintain satisfactory levels of service as defined by the SLA Agreement.</p>

                            <p>This Service Level Agreement (the “SLA”) governs the use of all products and services (collectively, the “Services”) offered by Exascale, to its customers (“Customer”).</p>

<h2>SERVER HARDWARE REPLACEMENT</h2>
<p>We always maintain a complete server with the same or better hardware specification as yours, ready to hand over instantly in the event of hardware failure.</p>

<h2>99.9% NETWORK UP-TIME</h2>
<p>We understand that no downtime is acceptable to your business. Our redundant Cisco Systems network allows us to guarantee that our network will be up-and-running all day, every day. In the unlikely event of network downtime occurring, we will credit you the value of one whole days’ service for every single hour your hosted solution is without connectivity.</p>

<h2>100% POWER AND HVAC AVAILABILITY</h2>
<p>Each Data centre is backed by a minimum of N+1 Power and Cooling. If power is lost the UPS’ seamlessly take over, moments later the generators will auto-start and enable the facility to provide power indefinitely until the mains supply is restored.</p>

<h2>MAINTENANCE</h2>
<p>Exascale will make all customers or third parties aware in advanced of any emergency or scheduled maintenance windows 3 days in advanced.</p>

<h2>EXCEPTIONS</h2>
<p>Circumstances beyond the reasonable control of Exascale (Exascale Ltd), including, without limitation, acts of any governmental body, war, insurrection, sabotage, armed conflict, embargo, fire, flood, strike or other labour disturbance, interruption of or delay in transportation, unavailability of or interruption or delay in telecommunications or third party services, virus attacks or hackers, failure of third party software (including, without limitation, eCommerce software, payment gateways, chat, statistics or free scripts) or inability to obtain raw materials, supplies, or power used in or equipment needed for provision of this SLA.</p>

<p>Customer’s acts or omissions (or acts or omissions of others engaged or authorized by customer), including, without limitation, custom scripting or coding (e.g. PHP, CGI, Perl, HTML, etc), any negligence, willful misconduct, or use of the Services in breach of Exascale’s Terms and Conditions and will null and void any potential SLA claims.</p>

<p>Scheduled maintenance, emergency maintenance and upgrades.</p>

<p>DNS issues which are outside the remit or direct control of Exascale (Exascale Ltd).</p>
                        </div>
                    </div>
            </div>
        </div>
    );
};

export default ServiceLevel;
