import React  from 'react'
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

const NotFound = () => {
    const pageTitle = 'Exascale';

    return (
      <div className="c-notfound">
          <Helmet>
              <title>{pageTitle}</title>
          </Helmet>
          <div className={'container-fluid'}>
              <div className="row">
                  <div className={'col-12 col-lg-6 min-vh-100-nav-adjusted bg-default-image d-none d-lg-flex'}></div>
                  <div className={'col-12 col-lg-6 min-vh-100-nav-adjusted d-flex align-content-center flex-wrap p-5'}>
                      <h1 className={'mb-4 w-100'}>Page not found</h1>
                      <p className={'mb-5 w-100'}>It seems like we couldn't find the page you were looking for.</p>
                      <p><Link to="/" className={'btn btn-branded'}>Return Home</Link></p>

                  </div>
              </div>
          </div>
      </div>
    );
  }
  
  export default NotFound;
  