import React from 'react';

// Components
import {Helmet} from "react-helmet";

const Cookies = () => {
    const pageTitle = 'Cookies';
    return (
        <div className={'c-cookies'}>
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
        </div>
    );
};

export default Cookies;
