import React from 'react';
import { Link } from 'react-router-dom';

// Components
import {Helmet} from "react-helmet";

const Legal = () => {
    const pageTitle = 'Legal';
    return (
        <div className={'c-legal c-content-page'}>
            <Helmet>
                <title>{pageTitle}</title>
                
            </Helmet>

            <div class="container-lg">
                    <div class="row">
                        <div className="col-12">
                            <div className="cms-divide">
                            <div className="contact-left">
                            <h1>Our Legalities</h1>

                            <h2>PRIVACY POLICY</h2>
                            <p>A Privacy Policy outlines how Exascale will collect, store and use your information during your visit to our website or when purchasing services.</p>
<Link to="/privacy-policy">View Privacy Policy</Link>

<h2>COPYRIGHT NOTICE</h2>
<p>The contents including but not limited to; Text, Images, Javascript, CSS are regarded as Copyright Exascale Limited 2021.</p>

<h2>TRADEMARK NOTICE</h2>
<p>The name ‘Exascale’ and its repspective logo in grey and yellow is a trademark of Exascale Limited. Any use or reproduction of such marks without written consent from Exascale Limited is prohibited.</p>

<h2>FRAUD PREVENTION</h2>
<p>To combat fraud, Exascale uses various third party services to gauge the risk associated with orders. Every order has an associated risk rating, orders which exceed the considered normal associated risk rating will require additional manual verification. A Photo ID Card, Utility Bill or Bank Statement may be required to prove your identity.</p>

<h2>ERRORS AND OMISSIONS NOTICE</h2>
<p>Exascale Limited or any associated third parties may withdraw or change; information, pricing or policies related to services without any prior notice.</p>

<h2>DOMAIN NAMES</h2>
<p>All .uk domain names must comply with the Nominet Terms and Conditions</p>
                        </div>
                        <div class="cms-right">
                            
                            <h1>View our policies</h1>
                            
                            <Link to="/complaints-procedure">Complaints Procedure</Link>
                            <Link to="/privacy-policy">Privacy Policy</Link>
                            <Link to="/service-level-agreement">Service Level Agreement</Link>
                            <Link to="/terms-of-service">Terms of Service</Link>
                        </div>
                        </div>
                        </div>
                    </div>
            </div>
        </div>
    );
};

export default Legal;
