import React from 'react';

// Components
import {Helmet} from "react-helmet";

const Complaints = () => {
    const pageTitle = 'Complaints Procedure';
    return (
        <div className={'c-complaints c-content-page'}>
            <Helmet>
                <title>{pageTitle}</title>
                
            </Helmet>

            <div class="container-lg">
                    <div class="row">
                        <div class="col-12">
                            <h1>Complaints Procedure</h1>

                            <p>We appreciate that, with a growing customer base, sometimes issues arise that need to be addressed. Your comments/ is important to us. We rely on feedback from our customers to identify the root causes of complaints. This allows us to put things right for you and ensure improvements to our service are made. We aim to do this quickly and efficiently.

                            <h2>STAGE 1: CONTACTING US</h2>
<p>If you have a complaint about any aspect of our service, then we would like to hear from you. Many outstanding issues can be resolved informally by discussing the issue with a member of staff. The support agent who deals with your query will aim to resolve any outstanding issues and reach an amicable resolution.</p>

<h2>STAGE 2: ESCALATING YOUR ISSUE</h2>
<p>Our support agents should be able to help you with your enquiry, if this is not the case you telephone us and ask for the Managing Director or you can escalate your issues by write to us.</p>

<h2>STAGE 3: RAISING AND APPEAL</h2>
<p>If after discussing any concerns you are dissatisfied with the response provided, or the way your issue has been dealt with, you can lodge an appeal by writing to the below address, detailing your account details and the outstanding complaint.</p>

Client Services Department:</p>
<p>Exascale Limited <br />Hilton Hall<br />Hilton Lane<br />Wolverhampton<br />Staffordshire<br />WV11 2BQ</p>

<p>Your complaint will be acknowledged in writing or via telephone within three working days of receiving the complaint. An investigation will be carried out into the issues raised and a full response will be provided within ten working days. Where the issue is particularly complex it may take longer to respond. If this is likely, we will provide information on the action which will be taken and advise when you can expect a full response.</p>
                        </div>
                    </div>
            </div>
        </div>
    );
};

export default Complaints;
