import React from 'react';
import { Helmet } from "react-helmet";
import { hostName } from './../utils/helpers';

const Error = (props) => {
  const pageTitle = 'Error - There has been an error processing your order';
  
  const { location } = props;
  const { state } = location;

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(state)
  };

  fetch(`${hostName()}/sendSupportEmail`, requestOptions)
  .then(response => {
      if(response.ok) {
      }
  })

  
  return (
      <div className={'c-order-error'}>
          <Helmet>
              <title>{pageTitle}</title>
          </Helmet>
          <div class="not-available-area">
              <div class="container container-lg">
                  <div class="row">
                      <div class="col-12">
                          <div class="not-available">
                              <h2>Something went wrong</h2>
                              <p>
                                  Our engineers have been made aware of the issue. 
                                  <br/>
                                  <br/>
                                  To keep up to date with how we’re progressing, follow us on Facebook:
                              </p>
                              <a href="#" class="btn-default">Follow us on Facebook</a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  );
  }
  
  export default Error;
  