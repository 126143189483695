import React, {useEffect, useState} from 'react';
import { Helmet } from "react-helmet";
import { useHistory, Link } from "react-router-dom";
import Slider from "react-slick";
import { globalStateValue } from './../../store/globalState';
import tick1 from './../../theme/img/tik.svg';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const OrderPackages = () => {

    const [state, dispatch] = globalStateValue();
    const history = useHistory();
    const [isLoading, setLoading] = useState(false);

    if(!state.customerData) {
        history.push('/')
    }

    var sliderSettings = {
        centerMode: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        centerPadding: '45px',
        focusOnSelect: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              dots: true,
              initialSlide: 1,
              infinite: false
            }
          }
        ]
    }

    const handlePackageSelection = (payload) => {
        setLoading(true)
        
        setTimeout(() => { 
            history.push('/checkout')
        }, 1000);


        dispatch({
            type: 'ADD_PLAN',
            selectedPlan: {
                payload
            }
        });
    }

      
    return (
        <div class="pricing-area">
            {isLoading &&
                <div className="loading-bar">
                    <div className="in"></div>
                </div>    
            }
        <div class="container-max-width">
            <div class="row">
                <div class="col-12 ">
                    <div class="title tcenter">
                        <span>We’re no more expensive than Sky or BT</span>
                        <h2>No hidden costs, broadband installed in 14 days.</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="pricing tcenter pricing-sldier"> 
                        <Slider {...sliderSettings}>
                        <div class="pricing-box">
                            <div class="pricing-top">
                                <span>Full Fibre 50</span>
                                <h2>£25.99</h2>
                                <h3>24 month contract</h3>
                                <span className="speed">50Mbps / <span className="small">50 Mbps</span></span>
                                <span className="package-heading">Average download / upload speed</span>
                            </div>
                            <div class="pricing-bottom">
                                <ul>
                                    <li>
                                        <img src={tick1} alt="tik" />
                                        <span>Truely unlimited downloads</span>
                                    </li>
                                    <li>
                                        <img src={tick1} alt="tik" />
                                        <span>FREE activation</span>
                                    </li>
                                    <li>
                                        <img src={tick1} alt="tik" />
                                        <span>FREE WiFi 6 Mesh Router</span>
                                    </li>
                                    <li>
                                        <span onClick={() => handlePackageSelection({
                                            "id": "fibre50",
                                            "name": "Full Fibre 50",
                                            "price": "£25.99",
                                            "downloadSpeed": "50",
                                            "uploadSpeed": "50"
                                        })} className={`btn-default`}>Buy Now</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="pricing-box">
                            <div class="pricing-top">
                                <span>Full Fibre 150</span>
                                <h2>£28.99</h2>
                                <h3>24 month contract</h3>
                                <span className="speed">150Mbps / <span className="small">150 Mbps</span></span>
                                <span className="package-heading">Average download / upload speed</span>
                            </div>
                            <div class="pricing-bottom">
                                <ul>
                                    <li>
                                        <img src={tick1} alt="tik" />
                                        <span>Truely unlimited downloads</span>
                                    </li>
                                    <li>
                                        <img src={tick1} alt="tik" />
                                        <span>FREE activation</span>
                                    </li>
                                    <li>
                                        <img src={tick1} alt="tik" />
                                        <span>FREE WiFi 6 Mesh Router</span>
                                    </li>
                                    <li>
                                    <span onClick={() => handlePackageSelection({
                                            "id": "fibre150",
                                            "name": "Full Fibre 150",
                                            "price": "£28.99",
                                            "downloadSpeed": "150",
                                            "uploadSpeed": "150"
                                        })} className={`btn-default`}>Buy Now</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="pricing-box">
                            <div class="pricing-top">
                                <span>Full Fibre 500</span>
                                <h2>£38.99</h2>
                                <h3>24 month contract</h3>
                                <span className="speed">500Mbps / <span className="small">500 Mbps</span></span>
                                <span className="package-heading">Average download / upload speed</span>
                            </div>
                            <div class="pricing-bottom">
                                <ul>
                                    <li>
                                        <img src={tick1} alt="tik" />
                                        <span>Truely unlimited downloads</span>
                                    </li>
                                    <li>
                                        <img src={tick1} alt="tik" />
                                        <span>FREE activation</span>
                                    </li>
                                    <li>
                                        <img src={tick1} alt="tik" />
                                        <span>FREE WiFi 6 Mesh Router</span>
                                    </li>
                                    <li>
                                    <span onClick={() => handlePackageSelection({
                                            "id": "fibre500",
                                            "name": "Full Fibre 500",
                                            "price": "£38.99",
                                            "downloadSpeed": "500",
                                            "uploadSpeed": "500"
                                        })} className={`btn-default`}>Buy Now</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="pricing-box">
                            <div class="pricing-top">
                                <span>Full Fibre 1000</span>
                                <h2>£48.99</h2>
                                <h3>24 month contract</h3>
                                <span className="speed">900Mbps / <span className="small">900 Mbps</span></span>
                                <span className="package-heading">Average download / upload speed</span>
                            </div>
                            <div class="pricing-bottom">
                                <ul>
                                    <li>
                                        <img src={tick1} alt="tik" />
                                        <span>Truely unlimited downloads</span>
                                    </li>
                                    <li>
                                        <img src={tick1} alt="tik" />
                                        <span>FREE activation</span>
                                    </li>
                                    <li>
                                        <img src={tick1} alt="tik" />
                                        <span>FREE WiFi 6 Mesh Router</span>
                                    </li>
                                    <li>
                                    <span onClick={() => handlePackageSelection({
                                            "id": "fibre900",
                                            "name": "Full Fibre 900",
                                            "price": "£48.99",
                                            "downloadSpeed": "900",
                                            "uploadSpeed": "900"
                                        })} className={`btn-default`}>Buy Now</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        </Slider>
                        </div>
                        
                    </div>
                    
             
            </div>
        </div>
    </div>
    )
}
export default OrderPackages;
  