import React, { useState } from 'react';
import { Link } from "react-router-dom";
import logo from './../../theme/img/logo.svg';

const OrderHeader = () => {
    return (
      <div className="order-header">
          <div className="container-fluid">
              <div className="row">
                  <div className="col-12">
                      <div className="container-max-width">
                            <Link to="/" className="order-logo" ><img src={logo} alt="logo" /></Link>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    );
  }
  
  export default OrderHeader;
  