import React, { createContext, useContext, useReducer } from 'react';
// eslint-disable-next-line
import firebaseConfig from './../utils/firebaseConfig';
import reducer, { initialState } from './../store/reducer';

export const StateContext = createContext();
const localState = JSON.parse(localStorage.getItem('exascale-app'));

export const Provider = props => {
  const { children } = props;

  return (
    <StateContext.Provider value={useReducer(reducer, localState || initialState)}>
        {children}
    </StateContext.Provider>
  );
};

// eslint-disable-next-line react-hooks/rules-of-hooks
export const globalStateValue = () => useContext(StateContext);