import { PostCodeRegex } from './constants';

export const escapeHTML = str => {
  var textArea = document.createElement('textarea');
  textArea.innerHTML = str;
  return textArea.value
}

export const urlAsName = url => {
  if(isNaN(url)) {
    const title = url.replace(/-/gi, ' ');
    let splitStr = title.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  } else {
    return url.toString();
  }
}

export const nameAsUrl = name => {
  if(name && typeof name === 'string') {
    return name.replace(/\s/g, '-').toLowerCase();
  }
}

export const nameAsEntity = name => {
  if(name && typeof name === 'string') {
    return name.replace(/\s/g, '').toLowerCase();
  }
}

export const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === '[::1]' ||
  // 127.0.0.1/8 is considered localhost for IPv4.
  window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
  )
);

export const isProduction = Boolean(
  window.location.hostname === 'order.exascale.co.uk'
)


export const hostName = () => {
  if(isLocalhost) {
      return 'http://localhost:5002/exascale-retail/us-central1';
  } else {
      return 'https://us-central1-exascale-retail.cloudfunctions.net';
  }
}

export const toFixed = (num, fixed) => {
  var re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');
  return num.toString().match(re)[0];
}


export const stringIncludes = (phrase, word) => {
    return phrase.includes(word);
}

export const validPostcode = postcode => {
    postcode = postcode.replace(/\s/g, '');
    return PostCodeRegex.test(postcode);
}

export const stripSpacesAndLower = value => {
    return value.replace(/\s/g, '').toLowerCase();
}


export const formatAddressForSelect = addressList => {
    return addressList.map(item => {
        return {
            value: `${item.number} ${item.street}`,
            label: `${item.number} ${item.street}`
      }
    });
}

export const formatPostcodeForCompare = postcode => {
    return postcode.toUpperCase().replace(/\s/g,'');
}

export const validEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const validTelephone = telephone => {
  const re = /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/;
  return re.test(telephone);
}