import React, { useState }  from 'react';
import { Link, Redirect } from 'react-router-dom';
// eslint-disable-next-line
import firebase from 'firebase';
import { useAuth } from '../../hooks/useAuth';
import {Helmet} from "react-helmet";


const Login = () => {
  const [error, setError] = useState(null);
  const { loggedIn,  doSignInWithEmailAndPassword, doLastLoggedIn } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);
  const [, setIsLoggedIn] = useState(false);
  const pageTitle = 'Login';

  const isInvalid = password === '' || email === '';

  const onSubmit = event => {
    doSignInWithEmailAndPassword(email, password)
      .then((reponse) => {
        doLastLoggedIn(email)
        setEmail('')
        setPassword('')
        setError(reponse)
        setIsLoggedIn(true)
      })
      .catch(error => {
        setError(error)
      });
 
    event.preventDefault();
  };

  const onEmailChange = event => {
    setEmail(event.target.value)
  };

  const onPasswordChange = event => {
    setPassword(event.target.value)
  };

  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };
 

    return (
      <div className="c-login mt-10">
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
          <div className={'container-fluid '}>
            <div className="row">
              <div className={'col-12 col-lg-6 min-vh-100-nav-adjusted bg-default-image d-none d-lg-flex'}>
              </div>
              <div className={'col-12 col-lg-6 min-vh-100-nav-adjusted d-flex align-content-center flex-wrap p-5'}>
                <h1 className={'mb-4 w-100'}>Login to Exascale</h1>
                <div className="form-group mb-5">
                  <span>Didn't register yet?</span>
                  <Link className='ml-2' to={`/register`}>
                    Register Now
                  </Link>
                </div>
                {error && error.message && <div className={'alert alert-danger'}><strong>Error:</strong> {error.message}</div>}
                <form onSubmit={onSubmit} className={'w-100'}>
                  <div className="form-group">
                    <input
                        className="form-control"
                        name="email"
                        value={email}
                        onChange={onEmailChange}
                        type="text"
                        placeholder="Email Address"
                    />
                  </div>
                  <div className="form-group">
                    <input
                        className="form-control"
                        name="password"
                        value={password}
                        onChange={onPasswordChange}
                        type={passwordShown ? "text" : "password"}
                        placeholder="Password"
                    />
                    <i onClick={togglePasswordVisiblity}>
                      {passwordShown ?
                          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" fill="#495057">
                            <path d="M0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0z" fill="none"/>
                            <path d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78l3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z"/>
                          </svg>
                          :
                          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" fill="#495057">
                            <path d="M0 0h24v24H0z" fill="none"/>
                            <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"/>
                          </svg>
                      }
                    </i>
                  </div>
                  <div className="form-group">
                    <button disabled={isInvalid} type="submit" className="btn-default">
                      Sign In
                    </button>
                  </div>
                  <div className="form-group">
                    <Link to={`/forgot-password`}>
                      Forgotten your password?
                    </Link>
                  </div>
                </form>
                {
                 loggedIn &&
                    <Redirect to='/manage'  />
                  }
              </div>
            </div>
          </div>
      </div>
    );
  }
  
  export default Login;
  