  export const initialState = {
    loggedIn: false,
    cart: [],
    products: [],
    locations: [],
    services: [],
    customer: [],
    account: []
  };
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case "LOGIN":
        return {
          ...state,
          loggedIn: action.loggedIn
      };
      case "USER_DETAILS":
        return handleIsAdmin(state, action);
      case "ADD_PLAN":
        return {
          ...state,
          plan: action.selectedPlan
        }
      case "UPDATE_PRODUCT_QTY":
        return handleProductQtyPayload(state, action);
      case "REMOVE_PRODUCT":
        return handleRemoveProductPayload(state, action);
      case "EMPTY_BASKET":
        return {
          cart: []
        }
      case "FETCH_LOCATIONS":
        return handleLocation(state, action);
      case "FETCH_SERVICES":
        return handleServices(state, action);
      case "UPDATE_CUSTOMER":
        return {
          ...state,
          customerData: action.customerData
      };
      default:
        return state;
    }
  };


  const handleIsAdmin = (state, action) => {
    const accountData = {
      ...state,
      account: action.userDocData
    }

    localStorage.setItem('exascale-app', JSON.stringify(accountData));
  }


  const handleLocation = (state, action) => {
    const locations = {
      ...state,
      locations: action.postCodes
    }

    localStorage.setItem('exascale-app', JSON.stringify(locations));
  }

  const handleServices = (state, action) => {
    const services = {
      ...state,
      services: action.allServices
    }

    localStorage.setItem('exascale-app', JSON.stringify(services));
  }


  const handleProductPayload = (state, action) => {
    let cart = state.cart;
    cart.push(action.payload);

    const cartData = {
      ...state,
      cart: state.cart
    }

    localStorage.setItem('exascale-app', JSON.stringify(cartData));

    return cartData;

  }


  const handleProductQtyPayload = (state, action) => {
    let cart = state.cart;
    let item = cart.find(item => item.sku === action.payload.sku);
    item.qty = action.payload.qty;

    let newCart = cart.filter(item => item.sku !== action.payload.sku);
   
    newCart.push(item);

    const cartData = {
      ...state,
        cart: newCart
    }

    localStorage.setItem('exascale-app', JSON.stringify(cartData));

    return cartData;

  }

  const handleRemoveProductPayload = (state, action) => {
    let cart = state.cart;

    const cartData = {
      ...state,
      cart: cart.filter(item => item.sku !== action.payload.sku)
    }

    localStorage.setItem('exascale-app', JSON.stringify(cartData));
    
    return cartData;
    
  }
  
  export default reducer;