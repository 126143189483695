import { useState, useEffect } from 'react';
import firebase from 'firebase';
import { isProduction } from '../utils/helpers'

export function useCustomers() {

  const generatePassword = (len) => {
    var length = (len)?(len):(10);
    var string = "abcdefghijklmnopqrstuvwxyz";
    var numeric = '0123456789';
    var punctuation = '!@#$%^&*()_+~`|}{[]\:;?><,./-=';
    var password = "";
    var character = "";
    var crunch = true;
    while( password.length < length ) {
        var entity1 = Math.ceil(string.length * Math.random()*Math.random());
        var entity2 = Math.ceil(numeric.length * Math.random()*Math.random());
        var entity3 = Math.ceil(punctuation.length * Math.random()*Math.random());
        var hold = string.charAt( entity1 );
        hold = (password.length %2 == 0) ? (hold.toUpperCase()):(hold);
        character += hold;
        character += numeric.charAt( entity2 );
        character += punctuation.charAt( entity3 );
        password = character;
    }
    password = password.split('').sort(function(){return 0.5-Math.random()}).join('');
    return password.substr(0,len);
}
  
  const doSignUp = (title, firstName, lastName, addressLineOne, addressLineTwo = '', town, postCode, telephone, dob = '', email, accountName, sortCode, accountNumber, terms, plan) => 
    firebase.auth().createUserWithEmailAndPassword(email, generatePassword())
        .then((userCredential) => {
        const user = userCredential.user;
            firebase.firestore().collection(isProduction ? 'customers' : 'staging_customers')
            .add({
            uid: user.uid,
            title,
            firstName,
            lastName,
            addressLineOne,
            addressLineTwo,
            town,
            postCode,
            telephone,
            dob,
            email,
            accountName,
            sortCode,
            accountNumber,
            terms,
            plan,
            orderStatus: 'Service Order Placed',
            lastModified: new Date()
            })
        })
        .catch((error) => {
            return error
        });

  const doLead = ({address1, address2 = '', address3 = '', email, firstName, lastName, number, postcode, provider = 'N/A', terms}) =>
    firebase.auth().createUserWithEmailAndPassword(email, generatePassword())
        .then((userCredential) => {
        const user = userCredential.user;
            firebase.firestore().collection(isProduction ? 'lead' : 'staging_lead')
            .add({
              uid: user.uid,
              firstName, 
              lastName, 
              addressLineOne: address1, 
              addressLineTwo: address2, 
              town: address3, 
              postcode, 
              telephone: number, 
              email, 
              provider,
              terms,
              lastModified: new Date()
            })
        })
        .catch((error) => {
            return error
        });

  return {
    doSignUp,
    doLead
  }
}