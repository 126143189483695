import React from 'react';

// Components
import {Helmet} from "react-helmet";

const ContactUs = () => {
    const pageTitle = 'Contact Us';
    return (
        <div className={'c-contact-us'}>
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            <div class=" contact-area">
                <div class="container container-lg">
                    <div class="row">
                        <div class="col-12">
                            <div class=" contact">
                                {/* <div class="contact-left">
                                    <h2>Contact us</h2>
                                    <p>Please send us a message using the form below and we’ll get back to you within 48
                                        hours.
                                    </p>
                                    <form class="form">
                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="First name" />
                                        </div>
                                        <div class="form-group">
                                            <input type="email" class="form-control" placeholder="Email address"/>
                                        </div>
                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="Phone number"/>
                                        </div>
                                        <div class="form-group">
                                            <textarea name="textarea" cols="30" rows="10" placeholder="Your message"
                                                class=""></textarea>
                                        </div>
                                        <div class="form-group form-check">
                                            <input type="checkbox" class="form-check-input form-control" id="checkbox"/>
                                            <label class="form-check-label " for="checkbox">
                                                I agree to the terms and conditions described to me and I am willing for
                                                some other legal stuff here that Tom should find out about.
                                            </label>
                                        </div>
                                        <button type="submit" class="btn-default">Send</button>
                                    </form>
                                </div> */}
                                <div class="contact-right">
                                    <div class="contact-right-inner">
                                        <h4>Email us</h4>
                                        <a href="#">hello@exascale.co.uk</a>
                                        <br/>
                                        <br/>
                                        <h4>Phone us</h4>
                                        <a href="#">+44 (0)330 010 0140</a>
                                        <br/>
                                        <br/>
                                        <h4>Complaints</h4>
                                        <a href="#">abuse@exascale.co.uk</a>
                                        <br/>
                                        <br/>
                                        <h4>Our address</h4>
                                        <address>
                                            Exascale, <br/>
                                            Trench Lock 3, <br/>
                                            Telford,<br/>
                                            TF1 5ST
                                        </address>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactUs;
