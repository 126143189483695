import React, { useState } from 'react';
import { useHistory, Link } from "react-router-dom";
import { useFetch } from 'react-fetch-hooks';
import { globalStateValue } from './../../store/globalState';
import { hostName } from './../../utils/helpers';

// Components
import {Helmet} from "react-helmet";

const CheckoutThanks = () => {
    // const [successSent, setSuccessSent] = useState(false);
    const [state] = globalStateValue();
    const pageTitle = 'Thank you for your order';
    const history = useHistory();
    
    if(!state.customerData || !state.plan) {
        history.push('/')
    } 

    useFetch(`${hostName()}/sendMail?email=${state.customerData.email}`);

    
    return (
        <div className={'c-checkout-thanks'}>
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            <div class="checkout-area what-happened-area container-max-width">
                <div class="container container-lg">
                    <div class="row">
                        <div class="col-12">
                            <div class="checkout">
                                <h2>Thank you for your order</h2>
                                <p>We cannot wait to connect you to our Gigabit Full Fibre network</p>
                            </div>
                            <div class="what-happened">
                                <h3>What happens next?</h3>
                                <ul>
                                    <li class="happened-1">
                                        <span class="number">1</span>
                                        <span class="text">We will create a direct debit with your bank account for {state?.plan?.payload?.price} p/m and you will receive confirmation once this has been setup</span>
                                    </li>
                                    <li class="happened-2">
                                        <span class="number">2</span>
                                        <span class="text">We will prove the infrastructure to your home</span>
                                    </li>
                                    <li class="happened-3">
                                        <span class="number">3</span>
                                        <span class="text">An Exascale representative will be in touch to book your installation</span>
                                    </li>
                                </ul>
                                <Link to="/" class="btn-default">Return to Homepage</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="checkout-content what-happened-content"></div>
        </div>
    );
};

export default CheckoutThanks;
